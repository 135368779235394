import { default as moment } from "moment";
import { atom } from "recoil";

export const currentMonthState = atom({
	key: 'currentMonthState',
	default: moment().date(1)
});

export const isDashboardLoadingState = atom({
	key: 'isDashboardLoadingState',
	default: true
});
