import React, {useEffect, useRef, useState} from "react";
import { useHistory } from "react-router";
import { useRecog } from "@praetorian-studio/recog/lib/recoil";
import axios from "axios";
import {API_HOSTNAME} from "../../recoil/applicationData";

export default function ForgotPassword(props: {}) {

	const buttonRef = useRef<HTMLButtonElement>(null);
	const history = useHistory();
	const [Recog] = useRecog();
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const keyDown = (e: any) => {
		if (e.keyCode === 13) {
			setEmail(e.target.value);
			alert("pressed email=" + e.target.value + " [" + email + "]");
		}
	}

	const completeCognitoForgot = (e: any) => {
		e && e.preventDefault();

		alert("hi value=[" + e.target.value + '] ' + email);

		console.log(API_HOSTNAME);

		if (email) {
			axios.post(`${API_HOSTNAME}/v1/authForgot`, { email }, {
			});
			history.push('/');
		} else {
			alert("Please enter an email address to use this feature.");
		}
	}

	const handleKeyPress = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
			alert("handleKeyPress [" + pattern.test(email) + "] [" + email + "]");
			if (!pattern.test(email)) {
				alert("Not valid");
				e && e.preventDefault();
			} else {
				alert("Valid");
				buttonRef.current?.click();
			}
		}
	}

	useEffect(() => {
		document.addEventListener("keypress", handleKeyPress);
		return () => (document.removeEventListener("keypress", handleKeyPress));
	}, []);

	return (
		<form autoComplete="off" onKeyDown={(e) => keyDown(e)}>
			<div className="text-light mb-3">
				<h4>Reset Password</h4>
				<p className="text-justify" style={{ fontSize: '0.7em' }}>
					Enter your email address below and click 'Reset Password'.
					If you have a BioHarmony account, you will be sent an email with further instructions.
				</p>
			</div>

			<ul className="inputs black-input large">
				<li>
					<span className="icon-user mid-margin-right" />
					{/*<input type="text" value={email} className="input-unstyled" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}/>*/}
					<input type="text" value={email} className="input-unstyled" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)}/>
				</li>
			</ul>

			<p className="button-height">
				<button className="button grey-gradient glossy float-left" onClick={() => history.push('/')}>Back</button>
				<button type="submit" className="button red-gradient glossy float-right" id="login" onClick={completeCognitoForgot}>Reset Password</button>
				<br />
			</p>
		</form>
	);

}
