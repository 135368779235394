import React, { ChangeEvent, useState } from "react";
import { Col, Container, Form, FormControl, InputGroup, Modal, Row, Alert } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import CreateModelWithButton from "../../../bioharmony/CreateModalWithButton";
import { updateFieldValue } from "../../../helpers/updateFieldValue";
import axios from 'axios';
import { API_HOSTNAME, authTokenState } from "../../../recoil/applicationData";

enum FormStatus {
	READY,
	PROCESSING,
	SUCCESS,
	ERROR
}

export default function CreateNewUser(props: {}) {

	const userTemplate = {
		firstName: '',
		lastName: '',
		email: '',
		username: '',
		clearance: '',
		phone: '',
		defaultOrganization: '',
		cognito: {
			email: '',
			username: ''
		}
	};

	const [status, setStatus] = useState<FormStatus>(FormStatus.READY);
	const [user, setUser] = useState<typeof userTemplate>(userTemplate);
	const authToken = useRecoilValue(authTokenState);

	const updateUser = (e: ChangeEvent<HTMLInputElement>) => {
		updateFieldValue<typeof userTemplate>(e, setUser, user);
	}

	const onCreate = async (e: MouseEvent): Promise<any> => {

		setStatus(FormStatus.PROCESSING);

		console.info(user);

		return axios.post(`${API_HOSTNAME}/v1/users`, user, {

			headers: {
				Authorization: authToken
			}

		}).then(() => {

			setStatus(FormStatus.SUCCESS);

			setTimeout(() => {
				setUser(userTemplate);
				setStatus(FormStatus.READY);
			}, 2000);

		}).catch(error => {

			setStatus(FormStatus.ERROR);
			throw error;

		});

	}

	// Testing purposes only
	// user.firstName = "Frank"; user.lastName = "Jones"; user.email = "dai.brook@aol.com"; user.username = "fjones"; user.cognito.email = user.email; user.phone = "250-555-1212";
	// user.cognito.username = "fjones"; user.clearance = "SYS"; user.defaultOrganization = "1";

	return <CreateModelWithButton label="Create New User" onCreate={onCreate}>
		{status === FormStatus.ERROR && <Alert variant="danger"> There was an error creating your user.</Alert>}
		{(status === FormStatus.READY || status === FormStatus.ERROR) &&
			<fieldset className="fieldset fields-list">

				<div className="field-block">
					<label className="label">Person Details</label>

					<Container fluid>
						<Row>
							<Col lg={12}>
								<Form>
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>First Name</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="First Name" name="firstName" onChange={updateUser} value={user.firstName} />
									</InputGroup>

									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Last Name</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Last Name" name="lastName" onChange={updateUser} value={user.lastName} />
									</InputGroup>

									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Email Address</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Email Address" name="email, cognito.email" onChange={updateUser} value={user.email} />
									</InputGroup>

									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Phone Number</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Phone Number" name="phone" onChange={updateUser} value={user.phone} />
									</InputGroup>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="field-block">
					<label className="label">Security Details</label>
					<Container fluid>
						<Row>
							<Col lg={12}>
								<Form>
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Username</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Username" name="username, cognito.username" onChange={updateUser} value={user.username} />
									</InputGroup>
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Clearance</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl as="select" name="clearance" value={user.clearance} onChange={updateUser} placeholder="Clearance">
											<option></option>
											<option value="SYS">System</option>
											<option value="ADM">Administrator</option>
											<option value="DEN">Data Entry</option>
											<option value="DLR">Dealer</option>
											<option value="OWN">Owner</option>
										</FormControl>
									</InputGroup>
									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Default Organization</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl as="select" name="defaultOrganization" onChange={updateUser} placeholder="Clearance" value={user.defaultOrganization}>
											<option></option>
											<option value={1}>BioHarmony</option>
										</FormControl>
									</InputGroup>
								</Form>
							</Col>
						</Row>
					</Container>
				</div>

				<div className="field-block">
					<label className="label">AWS Cognito</label>

					<Container fluid>
						<Row>
							<Col lg={12}>
								<Form>

									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Username</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Username" value={user.cognito?.username} />
									</InputGroup>

									<InputGroup>
										<InputGroup.Prepend>
											<InputGroup.Text>Email Address</InputGroup.Text>
										</InputGroup.Prepend>
										<FormControl placeholder="Email Address" value={user.cognito?.email} />
									</InputGroup>

								</Form>
							</Col>
						</Row>
					</Container>

				</div>

			</fieldset>}

		{status === FormStatus.PROCESSING && <Alert variant="info">Creating User {user.firstName} {user.lastName}...</Alert>}
		{status === FormStatus.SUCCESS && <Alert variant="success">User {user.firstName} {user.lastName} Successfully Created</Alert>}

	</CreateModelWithButton>;

}
