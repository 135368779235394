import React, {useState} from 'react';
import {useRecoilState, useRecoilValue} from "recoil";
import {useParams} from "react-router";
import {useSecurityApi} from "./api";
import {getUserQuery} from "../../api/users";
import {baseUserTerritoriesState, baseSecurityRolesState, baseTerritoryListState} from "./atoms";
import {userTerritoriesState, securityRolesState, territoryListState} from "./selectors";

export function useSecurityModel(){

    // api
    const SecurityApi = useSecurityApi();

    // state
    const { id } = useParams();
    const remoteUser = useRecoilValue( getUserQuery( id ));
    const [ user, setUser ] = useState<any>( remoteUser );
    const [ userTerritories, setUserTerritories ] = useRecoilState(baseUserTerritoriesState);
    const [ securityRoles, setSecurityRoles ] = useRecoilState(baseSecurityRolesState);
    const [ territoryLists, setTerritoryLists ] = useRecoilState(baseTerritoryListState);

    // VALUES
    const userTerritoriesList = useRecoilValue(userTerritoriesState);
    const securityRolesList = useRecoilValue(securityRolesState);
    const territoryList = useRecoilValue(territoryListState);

    // functions
    async function loadUserTerritories( userId:number ){
        try {
            const territories = await SecurityApi.userTerritories( user.id );
            console.info("useSecurityModel.userTerritories", territories);
            setUserTerritories(territories);
        } catch(e) {
            console.log(e);
        }
    }

    async function loadSecurityRoles() {
        try {
            const roles = await SecurityApi.roles();
            console.info("useSecurityModel.roles", roles);
            setSecurityRoles([roles]);
        } catch(e) {
            console.log(e);
        }
    }

    async function loadTerritoryList() {
        try {
            const territoryList = await SecurityApi.territoryList();
            console.info("useSecurityModel.territoryList", territoryList);
            setTerritoryLists([territoryList]);
        } catch(e) {
            console.log(e);
        }
    }

    return {
        userTerritoriesList,
        securityRolesList,
        territoryList,
        loadUserTerritories,
        loadSecurityRoles,
        loadTerritoryList
    }

}
