import _ from "lodash";
import React, { useEffect, useState } from "react";
import './style.scss';
import { ITComplexTableProps, IComplexTableData } from "../../app/SecurityManager/interfaces";
import Pagination from "./Pagination";
import { FormControl } from 'react-bootstrap';

export default function TerritoryTable(props: ITComplexTableProps) {

	const [data, setData] = useState(props.data);
	const [search, setSearch] = useState('');
	// const [selectedRows, setSelectedRows] = useState<number[]>([]);
	const [addRowFlag, setAddRowFlag] = useState(true);
	const [editRowData, setEditRowData] = useState<IComplexTableData>({
		id: -1,
		name: '',
		note: '',
		stateDate: ''
	});

	console.log('data values', data);
	const addData = () => {
		var lastIndex = 0;
		for (var d of data) {
			if (lastIndex < d.id) lastIndex = d.id;
		}
		setData([
			...data,
			{
				code: "",
				id: lastIndex + 1,
				name: "",
				note: "",
				startDate: "",
				newAdded: 1
			}
		])
		props.setEditableData({
			code: "",
			id: lastIndex + 1,
			name: "",
			note: "",
			startDate: ""
		})

		console.log("props.editableData", props.editableData);
		setAddRowFlag(false);
		console.log('data after add', data);
	}

	const removeAddData = () => {
		var arr: any[];
		console.log('data before pop', data, typeof (data));
		arr = data;
		// const deletedObj = arr.pop();
		arr.pop();
		setData([...arr]);
		console.log('data after pop', data, props.data, typeof (data));
		setAddRowFlag(true);
	}
	const _setAddRowFlag = () => {
		setAddRowFlag(true);
	}

	props.setCancelFunction(removeAddData);
	props.addFlag(_setAddRowFlag);

	useEffect(() => {

		setData(props.data.filter(item => {
			return Object.values(item).find(value => typeof value === "string" && value.includes(search));
		}));

		console.log('after use effect', data);

	}, [search]);

	useEffect(() => {

		setData([...props.data]);
	}, [props.data]);

	const setEditData = (e: any) => {
		props.setEditableData({
			...props.editableData,
			[e.target.name]: e.target.value,
		})
		setTimeout(() => {
			console.log('!!!!', props.editableData)
		}, 1000);
	}

	const setNameEditData = (e: any) => {
		props.setEditableData({
			...props.editableData,
			[e.target.name]: e.target.value,
			territoryId: parseInt(e.target.value)
		})
		setTimeout(() => {
			console.log('!!!!', props.editableData)
		}, 1000);
	}
	console.log("render--", editRowData);

	return (
		<>
			<div className={`table-header ${props.color}-gradient button-height`}>

				{/*{props.showSearch &&*/}
				{/*	<div className="float-right" id="search-box">*/}
				{/*		Search&nbsp;<input type="text" name="grid-header-table-search" id="grid-header-table-search" className="input mid-margin-left" value={search} onChange={(e) => setSearch(e.target.value)} />*/}
				{/*	</div>*/}
				{/*}*/}
				{/*<div className="clearfix" />*/}

			</div>

			<table className="table grid-table responsive-table responsive-table-on mb-0">
				<thead>
					<tr>
						{props.cells.map(cells => {

							cells = (Array.isArray(cells) && cells) || [cells];

							return (
								<th className={`header ${cells[0].className}`} scope="col">
									{ cells.map(cell => {
										return cell.header ? cell.header(cell.title) : cell.title;
									})}
								</th>
							);

						})}
					</tr>
				</thead>
				<tbody>
					{console.log('data before map', data, typeof (data))}
					{data.map((rowData, index) => (
						<tr>
							{props.cells.map(cells => {

								cells = (Array.isArray(cells) && cells) || [cells];

								return (
									<>
										<td className={`selectable ${cells[0].className}`}>
											{cells.map(cell => {
												const value = _.get(rowData, cell.dataKey);
												const editValue = _.get(props.editableData, cell.dataKey);
												if (props.editableData.id === rowData.id) {
													if (cell.format) {
														return (<>{cell.format(rowData.id, rowData)}</>);
													} else if (cell.dataKey === 'id') {
														return (
															<>{value}</>
														)
													}
													else if (cell.dataKey === 'name') {
														return (
															<>
																<FormControl value={editValue} as="select" name="name" onChange={setNameEditData}>
																	<option value={value}> {value ? value : '-- Please Select Territory Name --'} </option>
																	{props.territoriesList.map((item, index) => (
																		item.territoryName !== value ?
																			(<option value={item.territoryId}>{item.territoryName}
																			</option>) : <></>
																	))}
																</FormControl>
															</>
														);
													} else if (cell.type === 'date') {
														return (
															<>
																<FormControl value={editValue} type="date" name="startDate" onChange={setEditData}>
																</FormControl>
															</>
														);
													} else if (cell.dataKey === 'note') {
														return (
															<>
																<FormControl value={editValue} type="text" name="note" onChange={setEditData}>
																</FormControl>
															</>)
													}
													return (<></>);
												} else {
													if (cell.format) {
														return (<>{cell.format(rowData.id, rowData)}</>);
													} else if (cell.dataKey === 'id') {
														return (
															<>{value}</>
														)
													}
													else if (cell.dataKey === 'name') {
														return (
															<>
																<FormControl value={value} as="select" name="name" disabled>
																	<option value={value}>{value}</option>
																</FormControl>
															</>
														);
													} else if (cell.type === 'date') {
														return (
															<>
																<FormControl value={value} type="date" name="startDate" disabled>
																</FormControl>
															</>
														);
													} else if (cell.dataKey === 'note') {
														return (
															<>
																<FormControl value={value} type="text" name="note" disabled>
																</FormControl>
															</>)
													}
													return (<></>);
												}
											})}
										</td>
									</>
								);
							})}
						</tr>
					))}
				</tbody>
			</table>

			<form method="post" action="" className={`table-footer ${props.color}-gradient button-height`}>

				{/* OPTIONAL PAGINATION */}
				{props.showPagination && <div className="float-right"><Pagination /></div>}
				<div className="button-group">
					{/*{addRowFlag ? (*/}
					{/*	<button type="button" onClick={addData} className={`button ${props.color}-gradient glossy ${props.color === 'red' ? 'hidden' : ''}`} >+</button>*/}
					{/*) : (*/}
					{/*		<button type="button" onClick={removeAddData} className={`button icon-plus-round:before ${props.color}-gradient glossy ${props.color === 'red' ? 'hidden' : ''}`}>-</button>*/}
					{/*	)}*/}
				</div>

			</form>

		</>
	)

}
