import React, { PropsWithChildren } from "react";

export default function SimpleTable(props: PropsWithChildren<{ responsive?: boolean, headers?: string[] }>) {

	return (
		<table className={`simple-table ${props.responsive && 'responsive-table'}`}>
			{ props.headers?.length && (
				<thead>
					<tr>
						{props.headers.map((header, i) => <th key={i} scope="col" className="header">{header}</th>)}
					</tr>
				</thead>
			)}
			<tbody>
				{props.children}
			</tbody>
		</table>
	)

}
