import { default as moment, Moment } from "moment";
import React, { useEffect, useState } from "react";
import { useDrag } from 'react-dnd';
import { useRecoilValue } from "recoil";
import { useEventModel } from "../models/useEventModel";
import { IEvent } from "../models/useEventModel/interface";
import { LEGACY_HOSTNAME, urlParamToken } from "../recoil/applicationData";
import './AgendaEventStyle.scss';

export default function AgendaEvent(props: { event: IEvent, num?: number, on?: number, dragingEvent?: any, setFunction: () => void, onDraggingStarted: (ev: any) => void }) {

	const { handleMoveEvent } = useEventModel();

	const authToken = useRecoilValue(urlParamToken);
	const [day, setDay] = useState(moment(props.event.scheduled_date));
	const [agendaEventFrom, setAgendaEventFrom] = useState(day.hour());
	const [agendaEventTo, setAgendaEventTo] = useState(agendaEventFrom + 1);
	const [dragEvent, setDragEvent] = useState('');
	const on = Math.min(props.on || 1, 3);
	const num = Math.min(props.num || 1, 3);

	useEffect(() => {
		setAgendaEventFrom(day.hour());
	}, [day]);

	useEffect(() => {
		setAgendaEventTo(agendaEventFrom + 1);
	}, [agendaEventFrom])

	const [collectedProps, drag] = useDrag({
		item: { type: 'event-time' },
		begin: (_ignore: any) => {
			props.onDraggingStarted(props.event);
			// document.querySelector('dragEvent').style = 'display:none';
			// setDragEvent('dragEvent');
		},
		end: (item, monitor) => {
			const { from, to }: { from: number, to: number } = monitor.getDropResult() || {};
			// drag and drop on daily agenda window
			console.info("dropOnAgenda: ", day.format('YYYY-MM-DD[T]HH:00'), from, to, on, num, props.event);
			from && handleMoveEvent({ ...props.event }, day.set('hour', from));
			from && setAgendaEventFrom(day.hour());
			props.setFunction();
			// setDragEvent('');
		}
	});

	const getEditPath = () => {
		switch (props.event.type) {
			case "Maintenance": return "wtplan";
			case "Incident": return "wtincident";
			case "PreAuth": return "wtpaw";
		}
	}

	const onClickAgendaEvent = (e: any) => {
		e.preventDefault();
		// console.info(`Selected to Edit a ${props.event.type} task `);
		window.open(`${LEGACY_HOSTNAME}/index.php/${getEditPath()}/edit/${props.event.task_id}/${authToken}`, 'Edit_Event_Window')
	};

	const dragFunction = () => {
		// console.log('here dragging');
	}

	const allowed = (typeof props.event.allow_scheduling == 'undefined' ? true : props.event.allow_scheduling);
	const isComplete = (props.event.is_work_done ? '(C)' : '');
	console.log('test event components', allowed, props);

	if (allowed) {
		return (
			<>
				{/* {console.log('here drag', agendaEventFrom, agendaEventTo, props.event.color)}; */}
				<span ref={drag} className={`agenda-event from-${agendaEventFrom} to-${agendaEventTo} ${props.event.color}-gradient event-${num}-on-${on} ${props.dragingEvent ? 'dragEvent' : ''}`} onDrag={dragFunction} onClick={onClickAgendaEvent}>
					<time>{agendaEventFrom * 100} - {agendaEventTo * 100} - Serial No. {props.event.serial_number}</time>
					{props.event.title}
				</span>
			</>
		);
	}

	return (
		<>
			{/* {console.log('here  un  drag')}; */}
			<span className={`agenda-event from-${agendaEventFrom} to-${agendaEventTo} ${props.event.color}-gradient event-${num}-on-${on}`} onClick={onClickAgendaEvent}>
				<time>{agendaEventFrom * 100} - {agendaEventTo * 100} - Serial No. {props.event.serial_number}</time>
				{isComplete} {props.event.title}
			</span>
		</>
	);

}
