import Button from "./Button";
import Calendar from "./Calendar";
import Column from "./Column";
import Event from "./Event";
import EventsList from "./EventsList";
import Fact from "./Fact";
import Facts from "./Facts";
import Grid from "./Grid";
import Header from "./Header";
import Ribbon from "./Ribbon";
import SimpleTable from "./SimpleTable";
import Wrapped from "./Wrapped";
import PrintPopup from "./PrintPopup";


const Developr = {
	Button: Button,
	Calendar: Calendar,
	Column: Column,
	EventsList: EventsList,
	Event: Event,
	Facts: Facts,
	Fact: Fact,
	Grid: Grid,
	Header: Header,
	Ribbon: Ribbon,
	SimpleTable: SimpleTable,
	Wrapped: Wrapped,
	PrintPopup: PrintPopup
}

export default Developr;
