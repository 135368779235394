import moment from "moment";
import React from "react";
import { useRecoilValue } from "recoil";


import DashboardEventRow from "./DashboardEventRow";

export default function MaintenanceEvents() {

	// const events:IMaintenance[] = useRecoilValue();
	//
	// const color = ( event:IMaintenance ) => {
	// 	return event.scheduled_date ? 'black' : 'green';
	// }
	//
	// return (
	// 	<>
	// 		{ events.map(( event, index ) => (
	// 			<DashboardEventRow
	// 				key={ index }
	// 				num={ index + 1 }
	// 				type={event.schedule_code}
	// 				color={color(event)}
	// 				scheduled={event.scheduled_date && moment(event.scheduled_date).format('MMM YY')}
	// 				desc={event.title}
	// 				dealer={event.dealer}
	// 				serial={event.serial_number}
	// 			/>
	// 		))}
	// 	</>
	// )

	return <></>;

}
