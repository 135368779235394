import {ReCog} from "@praetorian-studio/recog/lib/ReCog";
import axios from 'axios';
import { API_HOSTNAME } from "../../recoil/applicationData";

export function useReportApi(){

    return {
        reportData: async ( report:number, m:number, y:number, d?:number ) => {
            // report = 1 => Monthly List
            //          2 => Daily List
            console.info("Inside Report API:", report, m, y, d);
            if (d === undefined) d = -1;

            return axios.get(`${API_HOSTNAME}/v1/report/reportData?report=${report}&m=${m}&y=${y}&d=${d}`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then( response => {
                // console.log("useReportApi", response);
                return response.data;
            }).catch( e => {
                console.log( '', e );
            });
        },
        plantForm: async ( report:number, sn:string ) => {
            // report = 3 => Treatment Unit (Plant) Form
            console.info("Inside Plant Form API:", report, sn);

            return axios.get(`${API_HOSTNAME}/v1/report/reportData?report=${report}&sn=${sn}`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then( response => {
                // console.log("useReportApi", response);
                return response.data;
            }).catch( e => {
                console.log( '', e );
            });
        }
    }
}
