import _ from "lodash";
import {ChangeEvent} from "react";

export function updateFieldValue<T extends Object>( e:ChangeEvent<HTMLInputElement>, set:(object:T)=>void, currentValue:T ){
	e.preventDefault();
	const data = { ...currentValue };
	const fields = e.target.name.split(",").map(field=>field.trim());
	fields.forEach(field => _.set( data, field, e.target.value ));
	set( data );
}
