import {default as moment} from 'moment';
import {selectorFamily} from "recoil";
import {scheduledEventsState} from "./selectors";

export const todaysEventsState = selectorFamily({
	key: 'todaysEventsState',
	get: ( today:string ) => {
		return ({ get }) => {
			return get(scheduledEventsState).filter( event => {
				return moment(event.scheduled_date).format("YYYY-MM-DD") === moment(today).format("YYYY-MM-DD");
			});
		}
	}
})
