import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router";
import { useRecoilValue } from "recoil";
import * as Users from "../../api/users";
import BioHarmony from "../../bioharmony";
import UserManager from "./UserManager";
import CreateNewUser from "./UserManager/create";
import UserTable from "./UserTable";

export default function SecurityManager(props: {}) {

	const activeUsers = useRecoilValue(Users.activeUsersState);
	const archivedUsers = useRecoilValue(Users.archivedUsersState);
	const [activeTab, setActiveTab] = useState('active-users');
	const match = useRouteMatch();

	return (
		<>
			<BioHarmony.TitleBar title="Security Manager">
				<Switch>
					<Route path={match.path} exact={true}>
						<CreateNewUser />
					</Route>
				</Switch>
			</BioHarmony.TitleBar>

			<Switch>
				<Route path={`${match.path}/:id/:tab`} component={UserManager} />
				<Route path={match.path}>
					<div className="with-padding">
						<Tabs id="security-mananger-tabs" active-key={activeTab} onSelect={(eventKey: string | null) => setActiveTab(eventKey as string)}>
							<Tab eventKey="active-users" title="System Users" className="with-padding">
								<UserTable users={activeUsers} color={'blue'} />
							</Tab>
						</Tabs>
					</div>
				</Route>
			</Switch>

		</>
	)

}
