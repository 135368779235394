import React from "react";

export default function ( props:{} ){

	return (
		<>
			<div className="button-group">
				<button type="button" className="button blue-gradient glossy icon-previous" title="First page"
				        id="grid-footer-navigate-first" />
				<button type="button" className="button blue-gradient glossy icon-backward"
				        title="Previous page" id="grid-footer-navigate-prev"/>
			</div>

			<div className="button-group">
				<button type="button" className="button blue-gradient glossy active"
				        id="grid-footer-navigate-page" title="Page 1">1
				</button>
			</div>

			<div className="button-group">
				<button type="button" className="button blue-gradient glossy icon-forward" title="Next page"
				        id="grid-footer-navigate-next"
				/>
				<button type="button" className="button blue-gradient glossy icon-next" title="Last page"
				        id="grid-footer-navigate-last" />
			</div>
		</>
	)

}
