import { atom, selector } from "recoil";
import { IUser } from "../data/applicationData";

export const HOSTNAME = window.location.hostname
export const HOSTNAME_IS_SECURE = HOSTNAME !== "localhost";
export const LEGACY_HOSTNAME = process.env.REACT_APP_LEGACY_URL || 'https://dev.bioharmony.xyz';
export const API_HOSTNAME = process.env.REACT_APP_API_URL || 'https://dev.api.mybioharmony.com/api';

export const urlParamToken = atom({
	key: 'urlParamToken',
	default: ''
});

export const authTokenState = selector({
	key: 'authTokenState',
	get: ({ get }) => {
		const accessToken = window.sessionStorage.getItem('com.localhost:idToken');
		return `Bearer ${ accessToken }`;
	}
});

export const activeUserState = atom({
	key: 'activeUserState',
	default: {} as IUser
});
