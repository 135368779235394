import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import './AgendaDropSlot.scss';

export default function AgendaDropSlot(props: { from: number, to: number }) {

	const [backgroundColor, setBackgroundColor] = useState('#000');
	const [opacity, setOpacity] = useState(1.0);
	const [dropEventFiled, setDropEventFiled] = useState('');

	const [collectedProps, drop] = useDrop({
		accept: 'event-time',
		drop: (item, monitor) => {

			return {
				from: props.from,
				to: props.to
			};

		},
		collect: (monitor) => {
			return {
				backgroundColor: monitor.isOver() ? '#CCC' : '#FFF',
				opacity: monitor.isOver() ? 0.5 : 0.0
			}
		}
	});

	useEffect(() => {
		setBackgroundColor(collectedProps.backgroundColor);
		setOpacity(collectedProps.opacity);
		console.log('test event --- ', collectedProps, props);
	}, [collectedProps]);

	return (
		<span ref={drop} className={`agenda-slot from-${props.from} to-${props.to} ${dropEventFiled}`} style={{ backgroundColor, opacity }}>

		</span>
	);

}
