import {atom} from "recoil";
import {IUserTerritory, ISecurityRole, ITerritoryList} from "./interface";

export const baseTerritoryListState = atom( {
    key: 'baseTerritoryListState',
    default: [] as ITerritoryList[]
});

export const baseUserTerritoriesState = atom({
    key: 'baseUserTerritoriesState',
    default: [] as IUserTerritory[]
});

export const baseSecurityRolesState = atom({
    key: 'baseSecurityRolesState',
    default: [] as ISecurityRole[]
});
