import {default as moment} from "moment";
import {Moment} from "moment/moment";
import React from 'react';
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {useEventsApi} from "./api";
import {agendaDayState, baseEventsState, statsState} from "./atoms";
import {todaysEventsState} from "./families";
import {IBaseEvent, IEvent} from "./interface";
import {eventsState, maintenanceEventsState, nonMaintenanceEventsState, scheduledEventsState} from "./selectors";

export function useEventModel(){

	// api
	const EventApi = useEventsApi();

	// state
	const [ baseEvents, setBaseEvents ] = useRecoilState(baseEventsState);
	const [ stats, setStats ] = useRecoilState(statsState);
	const [ agendaDay, setAgendaDay ] = useRecoilState( agendaDayState );

	// values
	const events = useRecoilValue(nonMaintenanceEventsState);
	const maintenance = useRecoilValue(maintenanceEventsState);

	// functions
	async function loadEvents( month:Moment ){

		try {
			const response = await EventApi.get( month.month()+1, month.year() );
			// console.debug(response);
			setStats( response.stats[0] );
			const newEvents = [ ...response.incidents, ...response.preAuth, ...response.maintenance ];
			setBaseEvents(newEvents);
		} catch(e) {
			console.log(e);
		}

	}

	function useEventsByDay( day:Moment ){
		const todaysEvents = useRecoilValue(todaysEventsState( day.format("YYYY-MM-DD")));
		return sort(todaysEvents);
	}

	function sort( events:IEvent[] ){

		events = [...events];
		events.sort(( event1:IEvent, event2:IEvent ) => {
			const a = moment(event1.scheduled_date);
			const b = moment(event2.scheduled_date);
			return a.isBefore(b) ? -1 : b.isBefore(a) ? 1 : 0;
		});
		return events;

	}

	// handlers
	function handleMoveEvent( event:IEvent, datetime:Moment ){

		// console.info("handleMoveEvent: ( is_work_done =", event.is_work_done, ")", datetime.format('YYYY-MM-DD[T]HH:00'), event);

		// change event time
		event.scheduled_date = datetime.format('YYYY-MM-DD[T]HH:00');

		// local move event
		const _events = [ ...baseEvents ];

		const index = baseEvents.findIndex(e=>{
			return e.task_id===event.task_id;
		});

		_events[ index ] = event as IBaseEvent;

		setBaseEvents( _events );

		// remove move event
		EventApi.schedule( event ).then( updatedEvent => {
			loadEvents( datetime );
		});

	}

	function handleSelectNextDay(){
		agendaDay && setAgendaDay(moment(agendaDay).add(1, 'day'));
	}

	function handleSelectPrevDay(){
		agendaDay && setAgendaDay(moment(agendaDay).add(-1, 'day'));
	}

	function handleUnselectDay(){
		setAgendaDay(null);
	}

	// factories
	function makeHandleDaySelection( day:Moment ){
		return () => {
			setAgendaDay(day);
		}
	}

	return {
		events,
		maintenance,
		stats,
		agendaDay,

		// hooks
		useEventsByDay,

		// functions
		loadEvents,

		// handlers
		handleMoveEvent,
		handleSelectPrevDay,
		handleSelectNextDay,
		handleUnselectDay,

		// factories
		makeHandleDaySelection
	}

}
