import {selector, selectorFamily} from "recoil";
import {IBaseEvent, IEvent} from "./interface";
import {baseEventsState} from "./atoms";

export const eventsState = selector<IEvent[]>({
	key: 'eventsState',
	get: ({ get }):IEvent[] => {

		return get(baseEventsState).map(( baseEvent:IBaseEvent ) => {
			
			const event:Partial<IEvent> = { ...baseEvent };

			// normalize
			event.month_num = event.month_num || Number(event.cur_month);
			event.scheduled_date = event.scheduled_date && (event.scheduled_date as string).substr(0,16);

			// add details
			switch( event.type_id ){
				case 1:
					event.type = "Maintenance";
					event.color = event.display_color;  // In daily view
					event.calendarClass = `green-bg`;   // In Calendar
					break;

				case 2:
					event.type = "PreAuth";
					event.color = "blue";
					break;

				case 3:
					event.type = "Incident";
					event.color = "red";
					event.calendarClass = "important";
					break;
			}

			return event as IEvent;

		});
	}
});

export const nonMaintenanceEventsState = selector({
	key: 'nonMaintenanceEventsState',
	get: ({ get }) => {
		return get(eventsState).filter( event => event.type !== "Maintenance" );
	}
});

export const maintenanceEventsState = selector({
	key: 'maintenanceEventsState',
	get: ({ get }) => {
		return get(eventsState).filter( event => event.type === "Maintenance" );
	}
});

export const unscheduledEventsState = selector({
	key: 'unscheduledEventsState',
	get: ({ get }) => {
		return get(eventsState).filter( event => !event.scheduled_date );
	}
});

export const scheduledEventsState = selector({
	key: 'scheduledEventsState',
	get: ({ get }) => {
		return [...get(eventsState)].filter( event => !!event.scheduled_date );
	}
});
