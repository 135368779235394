import ReCog from "@praetorian-studio/recog";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { API_HOSTNAME } from "../recoil/applicationData";
import axios from "axios";
import { urlParamToken } from "../recoil/applicationData";
import BioharmonyBigMenu from "./BioharmonyBigMenu";

export interface IMenuItem {
	children: IMenuItem[];
	menu_id: number;
	menu_name: string;
	security_level: string;
	role_id: string;
	menu_link: string;
}

export default function BioharmonyLegacyMenu(props: {}) {

	const [Recog, status] = ReCog.useRecog();
	const [urlParam, setUrlParam] = useRecoilState(urlParamToken);
	const [menu, setActualMenu] = useState<any>({})

	const setMenu = (menu: IMenuItem[]) => {

		const override: IMenuItem[] = [

		];
		setActualMenu([...menu, ...override]);
	};

	useEffect(() => {
		axios.get(`${API_HOSTNAME}/v1/application/menu`, {
			headers: {
				'Authorization': `Bearer ${Recog.idToken}`
			}
		}).then(response => {
			setMenu(response.data.data);
		})
	}, [])

	return <>
		{ !!Object.values(menu).length && <BioharmonyBigMenu items={menu} />}
	</>
}
