import React, { ChangeEvent, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import fabric from './fabric.png';
import grid from './grid.png';
import bioHarmonyLogo from './bioHarmony.png';
import { useRecog } from "@praetorian-studio/recog/lib/recoil";

export default function CognitoChallenge(props: {}) {

    const history = useHistory();
    const [Recog] = useRecog();
    const [password, setPassword] = useState<string>('');
    const [confirm, setConfirm] = useState<string>('');
    const [requiredAttributeValues, setRequiredAttributeValues] = useState<any>({});

    // useEffect(() => {
    // 	if(!sessionUserAttributes){
    // 		history.push("/");
    // 	}
    // })

    const completeCognitoChallenge = (e: any) => {

        e.preventDefault();

        if (password && confirm && password === confirm) {
            Recog.respondToNewPasswordChallenge(password);
        } else {
            alert("Password do not match. Try again.");
        }

    }

    const updateDynamicValue = (key: string, value: string) => {
        setRequiredAttributeValues({ ...requiredAttributeValues, ...{ [key]: value } });
    }

    const onRequiredAttributeChange = (e: ChangeEvent<HTMLInputElement>) => {
        updateDynamicValue(e.target.name, e.target.value);
    }

    return (
        <Container fluid style={{ height: '100vh', backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${fabric})` }}>
            <Row>
                <Col lg={4} />
                <Col lg={2} style={{ marginTop: '150px' }}>
                    <div className="align-center mt-3">
                        <img src={bioHarmonyLogo} alt="BioHarmony" />
                    </div>
                    <p className="text-dark" style={{ textAlign: 'left', fontSize: '0.7em' }}>
                        <br />
                        <strong>CONFIDENTIALITY NOTICE</strong><br />
                        THIS MESSAGE IS INTENDED ONLY FOR THE USE OF THE ADDRESSEE, AND MAY CONTAIN INFORMATION THAT IS
                        PRIVILEGED, CONFIDENTIAL AND EXEMPT FROM DISCLOSURE. IF THE READER OF THIS MESSAGE IS NOT THE
                        INTENDED RECIPIENT, OR THE PERSON RESPONSIBLE TO DELIVER IT TO THE INTENDED RECIPIENT, YOU ARE
                        HEREBY NOTIFIED THAT ANY DISSEMINATION, DISTRIBUTION OR COPYING OF THIS COMMUNICATION IS STRICTLY
                        PROHIBITED. IF YOU HAVE RECEIVED THIS COMMUNICATION IN ERROR, PLEASE IMMEDIATELY NOTIFY US.<br />
                        THANK YOU
                    </p>
                </Col>
                <Col lg={2} style={{ paddingTop: '150px', height: '100vh', backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) ), url(${grid})` }}>

                    <form autoComplete="off">
                        <div className="text-light mb-3">
                            <h4>Update Password</h4>
                            <p className="text-justify" style={{ fontSize: '0.7em' }}>A new password is required to use BioHarmony. Please set a new password below and click 'Update Password'.</p>
                        </div>

                        <ul className="inputs black-input large">
                            <li>
                                <span className="icon-lock mid-margin-right" />
                                <input type="password" value={password} className="input-unstyled" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />
                            </li>
                            <li>
                                <span className="icon-lock mid-margin-right" />
                                <input type="password" value={confirm} className="input-unstyled" placeholder="Confirm Password" onChange={(e) => setConfirm(e.target.value)} />
                            </li>
                        </ul>

                        {requiredAttributeValues.length &&
                            <>
                                <div className="text-light mb-3">
                                    <h4>Required Attributes</h4>
                                    <p className="text-justify" style={{ fontSize: '0.7em' }}>The following information must be completed
                    			prior to logging in and changing your password.</p>
                                </div>
                                <ul className="inputs black-input large">
                                    {requiredAttributeValues.map((attribute: string) => {

                                        const name = attribute[0].toUpperCase() + attribute.substr(1);
                                        const value = requiredAttributeValues[attribute] || "";

                                        return (
                                            <li>
                                                <span className="icon-lock mid-margin-right" />
                                                <input type="text" value={value} className="input-unstyled" placeholder={name}
                                                    name={attribute} onChange={onRequiredAttributeChange} />
                                            </li>
                                        )
                                    })}
                                </ul>
                            </>
                        }

                        <p className="button-height">
                            <button type="submit" className="button blue-gradient glossy float-right" onClick={completeCognitoChallenge}>Update Password</button>
                            <br />
                        </p>
                    </form>

                </Col>
                <Col lg={4} />
            </Row>
        </Container>
    );

}
