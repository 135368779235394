import React, { PropsWithChildren } from "react";
import { utilityClasses } from "./interfaces/IUtilityClasses";

export default function Wrapped(props: PropsWithChildren<{ class: utilityClasses[] }>) {

	const classes = props.class.join(' ');

	return (
		<div className={`wrapped ${classes}`}>
			{props.children}
		</div>
	)

}
