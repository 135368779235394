import { ReCog } from "@praetorian-studio/recog/lib/ReCog";
import axios from 'axios';
import { API_HOSTNAME } from "../../recoil/applicationData";
import { IUserTerritory } from "./interface";

export function useSecurityApi() {

    return {

        territoryList: async () => {
            return axios.get(`${API_HOSTNAME}/v1/application/territoryList`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                // console.debug("userSecurityApi", response);
                return response.data;
            }).catch(e => {
                console.log(e);
            });
        },

        userTerritories: async (userId: number) => {

            return axios.get(`${API_HOSTNAME}/v1/application/userTerritories?userId=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                // console.debug('user territories', response.data);
                return response.data;
            }).catch(e => {
                console.log(e);
            });
        },

        roles: async () => {

            return axios.get(`${API_HOSTNAME}/v1/application/securityRoles`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                // console.debug("userSecurityApi", response);
                return response.data;
            }).catch(e => {
                console.log(e);
            });
        },

        getTerritory: async (userId: number) => {
            console.info("GET:", userId);
            return axios.get(`${API_HOSTNAME}/v1/application/userTerritories?userId=${userId}`, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                console.debug('user territories', response.data);
                return response.data;
            }).catch(e => {
                console.log(e);
            });
        },

        patchTerritory: async (event: IUserTerritory) => {
            // updates
            console.info("PATCH:", event);
            return axios.patch(`${API_HOSTNAME}/v1/application/userTerritory`, { ...event }, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                return response.data;
            });
        },

        addTerritory: async (event: IUserTerritory) => {
            // adds
            return axios.post(`${API_HOSTNAME}/v1/application/userTerritory`, { ...event }, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                return response.data;
            });
        },

        updateTerritory: async (event: IUserTerritory) => {
            console.info('Territory to update', event);
            // update
            return axios.post(`${API_HOSTNAME}/v1/application/userTerritory`, { ...event }, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                return response.data;
            });
        },

        deleteTerritory: async (event: IUserTerritory) => {
            // deletes
            return axios.post(`${API_HOSTNAME}/v1/application/userTerritory`, { ...event }, {
                headers: {
                    'Authorization': `Bearer ${ReCog.idToken}`
                }
            }).then(response => {
                return response.data;
            });
        }

    }
}
