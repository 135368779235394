import React, { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Developr from '../developr/index';
import BioHarmony from '../bioharmony/index';
import { useEventModel } from "../models/useEventModel";
import { currentMonthState, isDashboardLoadingState } from "../recoil/dashboardData";
import PrintModal from '../developr/PrintPopup';
export default function Dashboard(props: {}) {

	const { events, maintenance, stats, loadEvents, agendaDay } = useEventModel();

	const month = useRecoilValue(currentMonthState);
	const monthSignature = month.format("YYYYMMDD");
	const setDashboardLoadingState = useSetRecoilState(isDashboardLoadingState);
	const [printMdlShow, setPrintMdlShow] = useState(false);

	useEffect(() => {
		window.onbeforeunload = function () { return null; };
		setDashboardLoadingState(true);
		loadEvents(month).then(() => {
			setDashboardLoadingState(false);
		});

	}, [monthSignature]);

	return (
		<>
			<PrintModal isShow={printMdlShow} hideModal={() => { setPrintMdlShow(false) }} label="Reporting List">
			</PrintModal>
			<hgroup id="main-title">
				<div className="float-right">
					<Developr.Button label="Print Reports" icon="printer" onClick={() => { setPrintMdlShow(true) }} />
				</div>
				<h1 className="underline" style={{ color: '#000' }}>Dashboard</h1>
			</hgroup>

			<div className="with-padding">

				<Developr.Grid>
					<Developr.Column columns={4}>
						<BioHarmony.QM n={1} />
					</Developr.Column>
					<Developr.Column columns={4}>
						<BioHarmony.QM n={2} />
					</Developr.Column>
					<Developr.Column columns={4}>
						<BioHarmony.QM n={3} />
					</Developr.Column>
					<Developr.Column columns={2} />
					<Developr.Column columns={10}>
						<Developr.Facts>
							<Developr.Fact value={stats.pending}>Pending Purchase Orders</Developr.Fact>
							<Developr.Fact value={stats.approved}>Approved Purchase Orders</Developr.Fact>
							<Developr.Fact value={stats.shipped}>Shipped Purchase Orders</Developr.Fact>
							<Developr.Fact value={stats.completed}>Complete Purchase Orders</Developr.Fact>
							<Developr.Fact value={stats.amended}>Amended Purchase Orders</Developr.Fact>
						</Developr.Facts>
					</Developr.Column>

					<Developr.Column columns={4}>

						{agendaDay && <BioHarmony.SelectedDayAgenda /> || <>
							<BioHarmony.UpcomingEvents events={events} />
							<BioHarmony.UpcomingEvents events={maintenance} title={"Maintenance"} />
						</>}

					</Developr.Column>

					<Developr.Column columns={8} style={{ position: 'sticky', top: 50 }}>
						<Developr.Calendar />
					</Developr.Column>

				</Developr.Grid>

			</div>

		</>
	);

}
