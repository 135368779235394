import React, {PropsWithChildren} from "react";

export default function Facts( props:PropsWithChildren<{}>){

	return (
		<div className={`facts clearfix`}>
			{props.children}
		</div>
	);

}
