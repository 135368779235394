import React, { PropsWithChildren } from "react";

export default function Ribbon(props: PropsWithChildren<{ tiny?: boolean, color?: string, tone?: string }>) {

	const color = props.color || 'silver';
	const tone = props.tone ? props.tone === "glossy" ? "gradient glossy" : "gradient" : "bg";

	return (
		<span className={`ribbon ${props.tiny && "tiny"}`}>
			<span className={`ribbon-inner ${color}-${tone}`}>
				{props.children}
			</span>
		</span>
	);

}
