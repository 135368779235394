import React from "react";

//
// Top bar title for the whole webpage (BioHarmony)
export default function Header(props: { title: string }) {
	return (
		<header role="banner" id="title-bar">
			<h2>{props.title}</h2>
		</header>
	);

}
