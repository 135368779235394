import React, { useEffect, useState } from "react";
import { default as moment, Moment } from 'moment';
import { useDrop } from 'react-dnd';
import { useRecoilValue, useSetRecoilState } from "recoil";
import CalendarDayEvent from "../bioharmony/CalendarDayEvent";

import { useEventModel } from "../models/useEventModel";
import { IEvent } from "../models/useEventModel/interface";


export default function CalendarDay(props: { day: Moment, month: Moment }) {

	const { makeHandleDaySelection, useEventsByDay } = useEventModel();

	const day = props.day;
	const month = props.month;

	const daySignature = day.format("YYYYMMDD");
	const monthSignature = month.format("YYYYMMDD");

	const [isCalDayInMonth, setIsCalDayInMonth] = useState(day.month() === month.month());
	const [defaultDateColor] = useState(day.format('YYYYMMDD') === moment().format('YYYYMMDD') && 'blue-bg white' || '');
	const [dateColor, setDateColor] = useState(defaultDateColor);
	const displayEvents = useEventsByDay(day);

	const [collectedProps, drop] = useDrop({
		accept: 'event',
		drop: (item, monitor) => {
			return { day };
		},
		collect: (monitor) => {
			return {
				dateColor: monitor.isOver() ? 'black-bg white' : defaultDateColor
			}
		}
	});

	useEffect(() => {
		setDateColor(collectedProps.dateColor);
	}, [collectedProps]);

	useEffect(() => {
		setIsCalDayInMonth(day.month() === month.month());
	}, [daySignature, monthSignature])

	return (
		<td ref={isCalDayInMonth && drop || undefined} key={`day-${day.format()}`} className={`week-end ${isCalDayInMonth || 'prev-month'}`} onClick={makeHandleDaySelection(day)}>
			<span className={`cal-day ${dateColor}`}>{day.date()}</span>
			{ !!displayEvents.length && <ul className="cal-events">
				{displayEvents.map(event => <CalendarDayEvent event={event} key={event.task_id} />)}
			</ul>}
		</td>
	);

}
