import React  from "react";
import './button.scss';

type Tone = "glossy" | "gradient";

export default function Button(props: { label: string, icon?: string, color?: string, tone?: Tone, onClick?: (e: any) => void }) {

	const color = props.color || "anthracite";
	const tone = props.tone ? props.tone === "glossy" ? "gradient glossy" : "gradient" : "bg";
	const onClick = props.onClick || (() => {
		// window.open(
		// 	"",
		// 	"Print List",
		// 	"width=600,height=400"
		// );
	});

	return (
		<button className={`button ${props.icon || `${color}-${tone}`}`} onClick={onClick}>
			{props.icon && <span className={`button-icon ${color}-${tone}`}><span className={`icon-${props.icon}`} /></span>}
			{props.label}
		</button>
	);
}
