import React from "react";
import { useDrag } from 'react-dnd';
import { useEventModel } from "../models/useEventModel";
import { IEvent, IMaintenance } from "../models/useEventModel/interface";
import { Moment } from 'moment';

export default function DashboardEventRow(props: { event: IEvent | IMaintenance }) {

	const { handleMoveEvent } = useEventModel();
	const isMaintenancePlan = props.event.type_id === 1;
	const label1 = isMaintenancePlan ? (props.event as IMaintenance).schedule_code : props.event.type;
	const color = (props.event.type_id === 3 ? 'red' : (props.event.type_id === 2 ? 'blue' : props.event.display_color));
	console.info('Event:', props.event.product_name, '[' + color + ']', props.event);
	const label = (label1 == null ? 'Custom' : label1);

	const [collectedProps, drag] = useDrag({
		item: { type: 'event' },
		end: (item, monitor) => {
			const { day }: { day: Moment } = monitor.getDropResult() || {};
			day && handleMoveEvent({ ...props.event }, day);
		}
	});

	// We only want the user to be able to move items that can be scheduled.
	const allowed = (typeof props.event.allow_scheduling == 'undefined' ? true : props.event.allow_scheduling);
	//console.debug('Allow scheduling for ',props.event.product_name, '? ', allowed);

	if (allowed) {
		return (
			<tr ref={drag} className="dashboard-event-row">
				<td className="align-center">
					<b>{props.event.serial_number}</b><br />
					<small className={`tag ${color}-bg`}>{label}</small>
				</td>
				<td>
					{props.event.title.substring(0, 48)}<br />
					<small>Dealer: {props.event.dealer}</small>
				</td>
			</tr>
		);
	}

	return (
		<tr className="dashboard-event-row">
			<td className="align-center">
				<b>{props.event.serial_number}</b><br />
				<small className={`tag ${color}-bg`}>{label}</small>
			</td>
			<td>
				{props.event.title.substring(0, 48)}<br />
				<small>Dealer: {props.event.dealer}</small>
			</td>
		</tr>
	);

}
