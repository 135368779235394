import {ReCog} from "@praetorian-studio/recog/lib/ReCog";
import axios from 'axios';
import { Moment, default as moment } from 'moment';
import {useRecoilValue} from "recoil";
import {API_HOSTNAME, authTokenState, urlParamToken} from "../../recoil/applicationData";
import {IEvent} from "./interface";

export function useEventsApi(){

	return {

		get: async ( month:number|string=moment().format('MM'), year:number|string=moment().format('YYYY') ) => {

			return axios.get( `${API_HOSTNAME}/v1/dashboard?month=${month}&year=${year}`, {
				headers: {
					'Authorization': `Bearer ${ReCog.idToken}`
				}
			}).then( response => {
				return response.data.data;
			}).catch( e => {
				console.log( e );
			});

		},
		post: async () => {
			// not initialized
		},

		schedule: async ( event:IEvent ) => {
			console.info(event);
			return axios.post(`${API_HOSTNAME}/v1/dashboard/scheduleEvent`, { ...event }, {
				headers: {
					'Authorization': `Bearer ${ReCog.idToken}`
				}
			}).then( response => {
				return response.data;
			});
		},

		event: ( eventId:number ) => {

			return {

				get: async () => {
					// not initialized
				},
				patch: async () => {
					// not initialized
				},
				put: async () => {
					// not initialized
				},
				delete: async () => {
					// not initialized
				}

			}

		}
	}

}
