import React, { PropsWithChildren } from "react";

export default function Fact(props: PropsWithChildren<{ value: string | number, unit?: string, progress?: number, progressUnit?: string }>) {

	const pUnit = props.progressUnit || "%";
	const progress = props.progress || 0;

	return (
		<div className="fact">
			<span className="fact-value">
				{props.value}{' '}
				{props.unit && <span className="fact-unit">{props.unit}</span>}
			</span>
			{props.children}<br />
			{ progress < 0 && <span className="fact-progress red">{progress}{pUnit} ▼</span>}
			{ progress > 0 && <span className="fact-progress green">{progress}{pUnit} ▲</span>}
		</div>
	);

}
