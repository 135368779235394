import React, { PropsWithChildren } from "react";
import converter from 'number-to-words';

interface IColumnProps extends React.HTMLAttributes<HTMLDivElement> {
	columns: number,
	tablet?: number,
	mobile?: number,
	landscape?: number,
	portrait?: number
}

export default function Column(props: PropsWithChildren<IColumnProps>) {

	const columns = props.columns && converter.toWords(props.columns);
	const tablet = props.tablet && converter.toWords(props.tablet);
	const mobile = props.mobile && converter.toWords(props.mobile);
	const landscape = props.landscape && converter.toWords(props.landscape);
	const portrait = props.portrait && converter.toWords(props.portrait);

	const className = [columns, tablet, mobile, landscape, portrait].filter(x => !!x).map((size: any) => {
		return size === "one" ? `${size}-column` : `${size}-columns`;
	}).join(' ');


	return (
		<div className={className} style={props.style}>
			{props.children}
		</div>
	);

}
