import React, { PropsWithChildren } from "react";

export default function Event(props: PropsWithChildren<{ number: string | number, title: string, subtitle?: string, href?: string, color?: string, onClick?: (e: any) => void }>) {

	const wrap = () => {
		switch (true) {

			case !!props.onClick:
				return <button onClick={props.onClick} className={`event-description`}>
					<h4>{props.title}</h4>
					{props.children}
				</button>;

			case !!props.href:
				return <a href={props.href} className={`event-description`}>
					<h4>{props.title}</h4>
					{props.children}
				</a>;

			default:
				return <span className={`event-description`}>
					<h4>{props.title}</h4>
					{props.children}
				</span>;

		}
	}

	return (
		<li>
			<span className={`event-date ${props.subtitle && 'with-month'}`}>
				{props.number}
				{props.subtitle && <span className={`event-month`}>{props.subtitle}</span>}
			</span>
			{ wrap()}
		</li>
	)

}
