import React, { useState } from "react";
import { useParams } from "react-router";

export default function CognitoChallenge(props: {}) {

	const { params } = useParams();
	const [password, setPassword] = useState<string>('');

	return (
		<form autoComplete="off">
			<div className="text-light mb-3">
				<h4>Reset Password</h4>
				<p className="text-justify" style={{ fontSize: '0.7em' }}>Please set a new password below and click 'Reset Password'.</p>
			</div>

			<ul className="inputs black-input large">
				<li>
					<span className="icon-lock mid-margin-right" />
					<input type="password" value={password} className="input-unstyled" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} />
				</li>
			</ul>

			<p className="button-height">
				<button type="submit" className="button red-gradient glossy float-right" id="login">Reset Password</button>
				<br />
			</p>
		</form>
	);

}
