import React from 'react';
import { RecoilRoot } from 'recoil';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import './App.css';
import { BrowserRouter } from "react-router-dom";
import BioHarmony from "./app/Bioharmony";
import Recog from '@praetorian-studio/recog';

function App() {

	// window.onbeforeunload = function () {
	// 	console.log('back button');
	// 	return "Your work will be lost.";
	// };
	const poolId = process.env.REACT_APP_POOL_ID || 'us-east-1_VqusyDgLL';
	const clientId = process.env.REACT_APP_CLIENT_ID || '6uvre125pjdfjlsu6k05hji40a';

	return (
		<RecoilRoot>
			<Recog.Root userPoolId={poolId} clientId={clientId}>
				<DndProvider backend={HTML5Backend}>
					<BrowserRouter>
						<React.Suspense fallback={<h2>Loading BioHarmony ...</h2>}>
							<BioHarmony />
						</React.Suspense>
					</BrowserRouter>
				</DndProvider>
			</Recog.Root>
		</RecoilRoot>
	);
}

export default App;
