import React, {PropsWithChildren, Ref, useRef, useState} from "react";
import {Modal} from "react-bootstrap";
import Developr from "../../developr";
import './style.scss';

export default function CreateModelWithButton( props:PropsWithChildren<{
	label:string,
	onCreate:(e:MouseEvent)=>Promise<any>
}> ){

	const [ showForm, setFormState ] = useState<boolean>(false);
	const showModal = () => setFormState(true);
	const hideModal = () => setFormState(false);

	const onClickCreate = (e:MouseEvent) => {
		props.onCreate(e).then(() => {
			hideModal();
		});
	};

	return (
		<>
			<Modal show={showForm} onHide={hideModal} size="lg" centered>
				<Modal.Header closeButton>
					<Modal.Title>{props.label}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{props.children}
				</Modal.Body>
				<Modal.Footer>
					<Developr.Button label="Cancel & Close" tone="glossy" onClick={hideModal} />
					<Developr.Button label={props.label} color="green" tone="glossy" onClick={onClickCreate} />
				</Modal.Footer>
			</Modal>

			<Developr.Button label={props.label} color="green" tone="glossy" icon="plus" onClick={showModal} />
		</>
	);

}
