import React from "react";
import Developr from '../developr';
import moment from 'moment';

export default function QM(props: { n: number }) {

	let m = props.n - 1;
	const now = moment().format("MMMM");
	const months = [m, m + 3, m + 6, m + 9].map(m => moment().month(m).format("MMMM"));
	const current = months.includes(now);

	return (
		<>
			<Developr.Wrapped class={['relative', 'align-center']}>
				<h4>QM #{props.n}</h4>
				{current && <Developr.Ribbon tiny color="red" tone="glossy">Now</Developr.Ribbon>}
				{months.map(month => {


					return <span key={month} className="qmMonthLabel">
						{month === now && <span className="active blue">{month}</span>}
						{month !== now && month}
					</span>;

				})}
			</Developr.Wrapped>
		</>
	);

}
