import {selector} from "recoil";
import {IUserTerritory, ISecurityRole, ITerritoryList} from "./interface";
import {baseUserTerritoriesState, baseSecurityRolesState, baseTerritoryListState} from "./atoms";

export const userTerritoriesState = selector<IUserTerritory[]>({
    key: 'userTerritoriesState',
    get: ({ get }):IUserTerritory[] => {
        return get(baseUserTerritoriesState).map(( userTerritories:IUserTerritory ) => {
            const territories:Partial<IUserTerritory> = { ...userTerritories };
            return territories as IUserTerritory;
        });
    }
});

export const securityRolesState = selector<ISecurityRole[]>({
    key: 'securityRolesState',
    get: ({ get }):ISecurityRole[] => {
        return get(baseSecurityRolesState).map(( securityRolesState:ISecurityRole ) => {
            const securityRoles:Partial<ISecurityRole> = { ...securityRolesState };
            return securityRoles as ISecurityRole;
        });
    }
});

export const territoryListState = selector<ITerritoryList[]>({
    key: 'territoryListState',
    get: ({ get }):ITerritoryList[] => {
        return get(baseTerritoryListState).map(( territoryListState:ITerritoryList ) => {
            const territoryList:Partial<ITerritoryList> = { ...territoryListState };
            return territoryList as ITerritoryList;
        });
    }
});
