import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSecurityApi } from "../../models/useSecurityModel/api";

export default function DeleteRow(props: any) {
    const territoryApi = useSecurityApi();

    const RemoveTerritoryRow = async () => {
        var params: any;
        params = {
            xref_id: props.xrefId,
            type: 'delete',
            user_id: parseInt(props.userId)
        }
        try {
            // const data = await territoryApi.deleteTerritory(params);
            await territoryApi.deleteTerritory(params);
            await props.removeTerritoryEvent();
            props.hideModal();
        } catch (error) {
            console.log('delete error', error);
        }
    }

    return (
        <>
            <Modal show={props.isShow} onHide={props.hideModal} animation={false}>
                <Modal.Body>Are you sure to remove '{props.name}'?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.hideModal}>
                        No
          			</Button>
                    <Button variant="danger" onClick={RemoveTerritoryRow}>
                        Yes
          			</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}