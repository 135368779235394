import React from 'react';
import { useRecoilState, useRecoilValue } from "recoil";
import { LEGACY_HOSTNAME, urlParamToken } from "../recoil/applicationData";
import { IMenuItem } from "./BioharmonyLegacyMenu";
import { useHistory } from "react-router";

export default function BioharmonyBigMenu(props: { items: IMenuItem[] }) {

	const menu = props.items;
	const urlParam = useRecoilValue(urlParamToken);
	const history = useHistory();

	return <ul className="big-menu">
		{menu.map((item, index) => {
			if (item.children.length) {
				return <li key={index} className="with-right-arrow"><span>{item.menu_name}</span>
					<BioharmonyBigMenu items={item.children} />
				</li>;
			} else if (item.menu_name == 'Log Out') {
				return <li key={index}><a href='#' onClick={() => { history.push('/logout') }} >Log Out</a></li>;
			} else {
				return <li key={index}><a href={`${LEGACY_HOSTNAME}${item.menu_link}/${item.security_level}/${urlParam}`}>{item.menu_name}</a></li>;
			}
		})}
	</ul>

}
