import React, { PropsWithChildren } from "react";
import './eventsList.scss';

export default function EventsList(props: PropsWithChildren<{}>) {

	return (
		<ul className={`events`}>
			{props.children}
		</ul>
	);

}
