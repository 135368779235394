import React from "react";
import { useRouteMatch } from "react-router";
import { useRecoilValue } from "recoil";
import * as Users from "../../api/users";
import Bioharmony from "../../bioharmony";
import { IComplexTableData } from "./interfaces";
import moment from 'moment';

export default function UserTable(props: { users: any, color: string }) {

	const match = useRouteMatch();

	const smallText = ( data: any ): any => {
		return <><br /><small>{data}</small></>;
	}

	return (
		<>
			<Bioharmony.ComplexTable data={props.users} color={props.color} showSearch cells={
				[
					{
						title: '',
						dataKey: 'id',
						className: 'align-center',
						format: ( data:any, row:any, actions:any ): any => {
							return <input type="checkbox" value={data} checked={actions.isChecked(data)} className="checkcolumn" onChange={(e)=>actions.selectRow(e.target.value, e.target.checked)}/>
						},
						header: ( data:any, actions:any ): any => {
							return <input type="checkbox" value={data} className="checkcolumn" onChange={(e)=>actions.selectAllRows(e.target.checked)}/>
						}
					},
					[
						{
							title: 'User Name',
							dataKey: 'name',
							className: 'align-left'
						},
						{
							title: 'Email Address',
							dataKey: 'email',
							header: smallText,
							format: ( value:any, row:any ): any => {
								return smallText( row.cognito.email || value );
							}
						}
					],
					[
						{
							title: 'Login',
							dataKey: 'login',
							className: 'align-center'
						},
						{
							title: 'Security Level',
							dataKey: 'role',
							format: ( value:any, row:any ): any => {
								return smallText(`[${value}]`);
							},
							header: smallText
						}
					],
					[
						{
							title: 'Territories',
							dataKey: 'territories',
							className: 'align-left',
							format: ( value:any, row:any ): any => {
								return value.map(( territory:any ) => territory.code ).join(', ');
							}
						},
						{
							title: 'Default Organization',
							dataKey: 'defaultOrganization',
							format: smallText,
							header: smallText
						}
					],
					{
						title: 'Author',
						dataKey: 'meta.lastUpdatedBy',
						className: 'align-center'
					},
					[
						{
							title: 'Last Edited By',
							dataKey: 'meta.lastUpdatedBy',
							className: 'align-center'
						},
						{
							title: 'Last Edited Date',
							dataKey: 'meta.lastUpdatedAt',
							format: ( value:any, row:any ): any => {
								return smallText(moment( value, "YYYY-MM-DD[T]HH:mm:ss.[000Z]").format("MMM DD YYYY HH:mm A"));
							},
							header: smallText
						}
					],
					{
						title: 'Status',
						dataKey: 'cognito',
						className: 'align-center',
						format: ( cognito:any, row:any ): any => {

							switch( true ){

								case !row.meta.isActive:
								case !cognito.enabled:
								case !cognito.isCognitoUser:
									return <span className="tag red-bg">Disabled</span>;

								case cognito.status === "COMPROMISED":
									return <span className="tag red-bg">COMPROMISED</span>;

								case cognito.status === "CONFIRMED":
									return <span className="tag green-bg">Active</span>;

								default:
									return <span className="tag orange-bg">Pending</span>;

							}

						}
					},
					{
						title: 'Live Look',
						dataKey: 'id',
						className: 'align-center',
						format: ( data:any ): any => {
							return <a href={`${match.path}/${data}/userInfo`} className="button compact icon-gear">View</a>;
						}
					}
				]
			} />
		</>
	)

}
