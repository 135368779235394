import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { getUserQuery } from "../../../api/users";
import Developr from "../../../developr";
import PersonTerritories from "./PersonTerritories";
import PersonSecurity from "./PersonSecurity";

export default function UserManager(props: {}) {

	const { id, tab } = useParams();
	const history = useHistory();
	const remoteUser = useRecoilValue(getUserQuery(id));
	const [user, setUser] = useState<any>(remoteUser);
	const [activeTab, setActiveTab] = useState<string>(tab);

	return (
		<React.Suspense fallback="Loading...">
			<div className="with-padding">

				<div className="float-right mb-3">
					{/*<Developr.Button label="Save" color="blue" tone="glossy" icon="tick" />{' '}*/}
					{/*<Developr.Button label="Save & Close" color="green" tone="glossy" icon="tick" />{' '}*/}
					<Developr.Button label="Close" color="red" tone="glossy" icon="cross" onClick={(e) => history.push('/security')} />
				</div>

				<h2>{user.name}</h2>

				<div className="clearfix" />

				<Tabs id="user-manager-tabs" defaultActiveKey={tab} active-key={activeTab} onSelect={(eventKey: string | null) => setActiveTab(eventKey as string)}>
					{/* <Tab eventKey="personInfo" title="Person Information" className="with-padding">
						<PersonInfo/>
					</Tab>
					<Tab eventKey="relationships" title="Relationships" className="with-padding">
						<PersonRelationships/>
					</Tab> */}
					<Tab eventKey="userInfo" title="Security" className="with-padding">
						<PersonSecurity />
					</Tab>
					<Tab eventKey="territories" title="Territories" className="with-padding">
						<PersonTerritories />
					</Tab>
				</Tabs>
			</div>
		</React.Suspense>
	)

}
