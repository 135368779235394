import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useSecurityModel } from "../../../models/useSecurityModel";
import { getUserQuery } from "../../../api/users";
import _ from 'lodash';
import { isSecurityRoleLoadingState, isUserTerritoriesLoadingState } from "../../../recoil/securityData";
import { isDashboardLoadingState } from "../../../recoil/dashboardData";
import { useSecurityApi } from "../../../models/useSecurityModel/api";

interface userSecurityRolesType {
    luId: String,
    code: String,
    name: String,
    isActive: Number,
    seqNumber: Number
}

export default function PersonSecurity(props: {}) {
    const userRoleApi = useSecurityApi();

    const { securityRolesList, loadSecurityRoles } = useSecurityModel();
    const setSecurityRolesLoadingState = useSetRecoilState(isSecurityRoleLoadingState);
    const [userSecurityRoles, setUserSecurityRoles] = useState<userSecurityRolesType[]>([])
    useEffect(() => {
        setSecurityRolesLoadingState(true);
        loadSecurityRoles().then(() => {
            securityRoles();
            setSecurityRolesLoadingState(true);
        });
    }, []);
    const securityRoles = (async () => {
        var _userSecurityRoles: userSecurityRolesType[];
        _userSecurityRoles = [];
        const userSecurityRoles = await userRoleApi.roles();
        if (userSecurityRoles.data)
            for (var d of userSecurityRoles.data) {
                _userSecurityRoles.push({
                    luId: d.lu_id,
                    code: d.code,
                    name: d.name,
                    isActive: d.is_active,
                    seqNumber: d.seq_number
                })
            }

        await setUserSecurityRoles(_userSecurityRoles);
    })

    const { id, tab } = useParams();
    const history = useHistory();
    const remoteUser = useRecoilValue(getUserQuery(id));
    const [user, setUser] = useState<any>(remoteUser);
    const [activeTab, setActiveTab] = useState<string>(tab);

    const syncUserEmail = (e: any) => {
        e.preventDefault();
        setUser({
            ...user,
            email: user.cognito?.email
        });
    }

    const updateThisValue = (e: any) => {
        e.preventDefault();
        const data = { ...user };
        _.set(data, e.target.name, e.target.value);
        setUser(data);
    }

    console.debug("isSecurityRoleLoadingState", isSecurityRoleLoadingState);

    return (

        <fieldset className="fieldset fields-list">

            <div className="field-block">
                <label className="label">Person Details</label>

                <Container fluid>
                    <Row>
                        <Col lg={8}>
                            <Form>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>First Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="First Name" value={user.firstName} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Last Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Last Name" value={user.lastName} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Email Address</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Email Address" name="email" onChange={updateThisValue} value={user.cognito?.email} />
                                    {user.cognito?.email &&
                                        <InputGroup.Append>
                                            {/*<Button size={"sm"} variant="outline-secondary" onClick={syncUserEmail}>Sync</Button>*/}
                                        </InputGroup.Append>
                                    }
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="field-block">
                <label className="label">Security Details</label>
                <Container fluid>
                    <Row>
                        <Col lg={8}>
                            <Form>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Username</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Username" value={user.login} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Security Role</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl as="select" placeholder="Clearance" disabled={true}>
                                        {isSecurityRoleLoadingState &&
                                            userSecurityRoles.map(({ code, name }) => {
                                                return (
                                                    <option>{code}: {name}</option>
                                                );
                                            })
                                        }
                                    </FormControl>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Default Organization</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl as="select" placeholder="Clearance" disabled={true}>
                                        <option>BioHarmony</option>
                                    </FormControl>
                                </InputGroup>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="field-block">
                <label className="label">AWS Cognito</label>

                <Container fluid>
                    <Row>
                        <Col lg={8}>
                            <Form>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Login Name</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Login" value={user.cognito?.username} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Email Address</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl placeholder="Email Address" value={user.cognito?.email} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Cognito Enabled</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl disabled placeholder="Cognito Status" value={user.cognito?.enabled ? "ENABLED" : "DISABLED"} />
                                </InputGroup>

                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>Cognito Status</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl disabled placeholder="Cognito Status" value={user.cognito?.status} />
                                </InputGroup>

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </fieldset>
    );
}