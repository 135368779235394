import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import Bioharmony from "../../bioharmony";
import DeleteRow from "./DeleteRow"
import { IComplexTableData } from "../../app/SecurityManager/interfaces";
import { useSecurityApi } from "../../models/useSecurityModel/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from 'react-router';
import { getUserQuery } from "../../api/users";
import { Alert } from 'react-bootstrap';
import { isSecurityRoleLoadingState } from "../../recoil/securityData";

interface _terrioriesListType {
	territoryId: Number,
	territoryName: String,
}

export default function TerritoriesTable(props: { userTerritoriList: any, __territoriesList: any, color: string, getUseSecurityDatas: () => void }) {

	const match = useRouteMatch();
	const territoryApi = useSecurityApi();
	const terrtoryTableFunction = Bioharmony.TerritoristTable;
	const setSecurityRolesLoadingState = useSetRecoilState(isSecurityRoleLoadingState);

	const { id } = useParams();
	const remoteUser = useRecoilValue(getUserQuery(id));
	const [user, setUser] = useState<any>(remoteUser);
	const [successEdit, setSuccessEdit] = useState(false);
	const [failedEdit, setFailedEdit] = useState(false);
	const [successAdded, setSuccessAdded] = useState(false);
	const [failedAdded, setFailedAdded] = useState(false);
	const [successRemoved, setSuccessRemoved] = useState(false);
	const [failedRemoved, setFailedRemoved] = useState(false);
	const [territoriesList, setTerritoriesList] = useState<_terrioriesListType[]>([]);
	const [editableData, setEditableData] = useState<IComplexTableData>({
		id: -1,
		xrefId: 0,
		seqId: 0,
		name: '',
		note: '',
		startDate: ''
	});
	const [territoriList, setTerritoriList] = useState<any[]>([]);
	const [xrefId, setXrefId] = useState(0);
	const [name, setName] = useState('');
	const [territoryName, setTerritoryName] = useState(String);

	const [removedData, setRemovedData] = useState<{}>({})
	const [show, setShow] = useState(false);

	const onEdit = (data: IComplexTableData) => {
		setEditableData({
			...data
		});
	}

	useEffect(() => {
		setSecurityRolesLoadingState(true);
		getTerritoriesList().then(() => {
			setSecurityRolesLoadingState(false);
		});
	}, []);

	const getTerritoriesList = async () => {
		var _territoriList: _terrioriesListType[];
		_territoriList = [];
		const _territoryList = await territoryApi.territoryList();
		for (var d of _territoryList.data) {
			_territoriList.push({
				territoryId: d.territory_id,
				territoryName: d.territory_name
			})
		}
		await setTerritoriesList(_territoriList);
	}

	const getTerritoryName = async (territoryId: any) => {

		var territoryName: any;
		console.log('here test territory getting function', territoryId)
		for (var x of territoriesList) {
			console.log('here territory test name ---  ', x)
			if (x.territoryId == territoryId) {
				console.log('here territory test name', x)
				territoryName = x.territoryName;
				// setTerritoryName(territoryName);
				// return;
				return territoryName;
			}
		}
	}

	const onDelete = async (data: any) => {
		setRemovedData(data);
		setXrefId(data.xrefId);
		setName(data.name);
		setShow(true);
	}

	const undo = () => {
		setEditableData({
			id: -1,
			name: '',
			note: '',
			stateDate: ''
		});
	}

	let cancelFunction: any;
	cancelFunction = null;
	const setCancelFunction = (fnCancel: any) => {
		cancelFunction = fnCancel;
	}

	const cancel = () => {
		if (cancelFunction) {
			cancelFunction();
		}
	}

	let addFlagFunction: any;
	addFlagFunction = null;
	const setAddFlagFunction = (fnAddFlag: any) => {
		addFlagFunction = fnAddFlag;
	}

	const AddFlag = () => {
		if (cancelFunction) {
			cancelFunction();
		}
	}

	const onSave = async (id: any, type: string) => {
		console.log('save data', editableData);

		props.userTerritoriList.map((item: any) => {
			if (type == 'add' && item.territoryId.toString() == editableData.name)
				console.log('double');
		})

		var arr: any;
		console.log('test type---', typeof (editableData.territoryId))
		if (type == 'add') {
			arr = {
				type: 'add',
				xref_id: '',
				user_id: user.id,
				territory_id: parseInt(editableData.name),
				scheduled_date: editableData.startDate,
				note: editableData.note
			}
		} else if (type == 'update') {
			arr = {
				type: 'update',
				xref_id: editableData.xrefId,
				user_id: user.id,
				territory_id: editableData.territoryId,
				scheduled_date: editableData.startDate,
				note: editableData.note
			}
		}

		try {
			const data = await territoryApi.updateTerritory(arr);
			await props.getUseSecurityDatas();
			if (type == 'update') {
				setSuccessEdit(true);
				setTimeout(() => {
					setSuccessEdit(false);
				}, 3000);
			}
			if (type == 'add') {
				setSuccessAdded(true);
				setTimeout(() => {
					setSuccessAdded(false);
				}, 3000);
				AddFlag();
				const editableDataName = await getTerritoryName(parseInt(editableData.name));
				setTerritoriList([...props.userTerritoriList,
				{
					id: editableData.id,
					name: editableDataName,
					startDate: editableData.startDate,
					note: editableData.note,
					xrefId: data.xref
				}])
				// await props.getUseSecurityDatas();
			}
			// setTerritoriList([...props.userTerritoriList])
			setEditableData({
				id: -1,
				name: '',
				note: '',
				stateDate: '',
			});
		} catch (error) {
			console.log('add update error', error);
			if (type == 'update') {
				setFailedEdit(true);
				setTimeout(() => {
					setFailedEdit(false);
				}, 3000);
			}
			if (type == 'add') {
				setFailedAdded(true);
				setTimeout(() => {
					setFailedAdded(false);
				}, 3000);
			}
			return null;

		}
	}

	const removeTerritoryRow = async () => {
		await props.getUseSecurityDatas();
		setSuccessRemoved(true);
		setTimeout(() => {
			setSuccessRemoved(false);
		}, 3000);
	}

	useEffect(() => {
		setTerritoriList([...props.userTerritoriList])
	}, [props.userTerritoriList])
	return (
		<>
			{console.log('territory list test', props.__territoriesList)}
			<DeleteRow xrefId={xrefId} name={name} isShow={show} userId={id} hideModal={() => { setShow(false) }} removeTerritoryEvent={removeTerritoryRow}></DeleteRow>
			<Bioharmony.TerritoristTable setEditableData={(data) => setEditableData({
				...data
			})} setCancelFunction={setCancelFunction} addFlag={setAddFlagFunction} data={territoriList} territoriesList={props.__territoriesList} color={props.color} editableData={editableData} showSearch cells={
				[
					[
						{
							title: 'Id',
							dataKey: 'id',
							className: 'align-center',
							type: 'number'
						},
					],
					[
						{
							title: 'Territory',
							dataKey: 'name',
							className: 'align-left',
							type: 'text',
						},
					],
					[
						{
							title: 'Start Date',
							dataKey: 'startDate',
							className: 'align-center',
							type: 'date',
						},
					],
					[
						{
							title: 'Note',
							dataKey: 'note',
							className: 'align-center',
							type: 'text',
						},
					],
					// [
					// 	{
					// 		title: 'Live Look',
					// 		dataKey: 'id',
					// 		className: 'align-center',
					// 		type: '',
					// 		format: (id: any, data: any): any => {
					// 			if (editableData.id == id) {
					// 				return (
					// 					<>
					// 						{
					// 							data.newAdded && data.newAdded == 1 ?
					// 								(
					// 									<>
					// 										<button style={{ marginRight: '5px' }} onClick={() => { onSave(id, 'add'); }} className="button compact icon-gear">Save</button>
					// 										<button onClick={cancel} className="button compact icon-redo">Cancel</button>
					// 									</>
					// 								) : (
					// 									<>
					// 										<button style={{ marginRight: '5px' }} onClick={() => { onSave(id, 'update'); }} className="button compact icon-gear">Save</button>
					// 										<button onClick={undo} className="button compact icon-trash">Undo</button>
					// 									</>
					// 								)
					// 						}
					//
					// 					</>
					// 				);
					// 			} else {
					// 				return (<><button style={{ marginRight: '5px' }} onClick={() => { onEdit(data); }} className="button compact icon-gear">Edit</button><button onClick={() => { onDelete(data) }} className="button compact icon-trash">Delete</button></>);
					// 			}
					// 		}
					// 	}
					// ],
				]
			} />
			{
				successEdit ? (
					<Alert variant={'success'}>
						Successfully updated!
					</Alert>
				) : failedEdit ? (
					<Alert variant={'success'}>
						Update Error !
					</Alert>
				) : successAdded ? (
					<Alert variant={'success'}>
						Successfully Added!
					</Alert>
				) : failedAdded ? (
					<Alert variant={'success'}>
						Adding Error!
					</Alert>
				) : successRemoved ? (
					<Alert variant={'success'}>
						Successfully Removed!
					</Alert>
				) : failedRemoved ? (
					<Alert variant={'success'}>
						Remove Error!
					</Alert>
				) : <></>
			}
		</>
	)
}
