import React, { PropsWithChildren } from "react";

export default function Grid(props: PropsWithChildren<{}>) {

	return (
		<div className="columns">
			{props.children}
		</div>
	);

}
