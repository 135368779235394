import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router";
import CognitoChallenge from "./CognitoChallenge";
import ForgotPassword from "./ForgotPassword";
import LoginForm from "./LoginForm";
import ResetPassword from "./ResetPassword";
import bioHarmonyLogo from './bioHarmony.png';
import fabric from './fabric.png';
import scratches from './scratches.png';
import grid from './grid.png';
import moment from 'moment';
import './login.scss';

export default function Login(props: { auth: (Username: string, Password: string) => void }) {

	const route = useRouteMatch();

	return (
		<>
			<Container fluid style={{ height: '100vh', backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) ), url(${fabric})` }}>
				<Row>
					<Col lg={4} />
					<Col lg={2} style={{ marginTop: '150px' }}>
						<div className="align-center mt-3">
							<img src={bioHarmonyLogo} alt="BioHarmony" />
						</div>
						<p className="text-dark" style={{ textAlign: 'left', fontSize: '0.7em' }}>
							<br />
							<strong>CONFIDENTIALITY NOTICE</strong><br />
							THIS MESSAGE IS INTENDED ONLY FOR THE USE OF THE ADDRESSEE, AND MAY CONTAIN INFORMATION THAT IS
							PRIVILEGED, CONFIDENTIAL AND EXEMPT FROM DISCLOSURE. IF THE READER OF THIS MESSAGE IS NOT THE
							INTENDED RECIPIENT, OR THE PERSON RESPONSIBLE TO DELIVER IT TO THE INTENDED RECIPIENT, YOU ARE
							HEREBY NOTIFIED THAT ANY DISSEMINATION, DISTRIBUTION OR COPYING OF THIS COMMUNICATION IS STRICTLY
							PROHIBITED. IF YOU HAVE RECEIVED THIS COMMUNICATION IN ERROR, PLEASE IMMEDIATELY NOTIFY US.<br />
							THANK YOU
						</p>
					</Col>
					<Col lg={2} style={{ paddingTop: '150px', height: '100vh', backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0) ), url(${grid})` }}>

						<Switch>
							<Route path='/reset/:key' component={ResetPassword} />
							<Route path='/forgot' component={ForgotPassword} />
							<Route path='/'>
								<LoginForm auth={props.auth} />
							</Route>
						</Switch>

					</Col>
					<Col lg={4} />
				</Row>
			</Container>

		</>
	);

}
