import React from "react";
import { useDrag } from "react-dnd";
import { useRecoilValue } from "recoil";
import { useEventModel } from "../models/useEventModel";
import { IEvent } from "../models/useEventModel/interface";
import { LEGACY_HOSTNAME, urlParamToken } from "../recoil/applicationData";
import moment from 'moment';

export default function CalendarDayEvent(props: { event: IEvent }) {

	const { handleMoveEvent } = useEventModel();
	const authToken = useRecoilValue(urlParamToken);

	const [, drag] = useDrag({
		item: { type: 'event' },
		end: (item, monitor) => {

			const dropResult = monitor.getDropResult() || {};

			dropResult.day && handleMoveEvent({ ...props.event }, dropResult.day.hour(moment(props.event.scheduled_date, "YYYY-MM-DD[T]HH:00").hour()));

		}
	});

	const onClickEvent = (e: any) => {
		e.preventDefault();
		setTimeout(() => {
			window.open(`${LEGACY_HOSTNAME}/index.php/workorder/edit/${props.event.workorder_id}/${authToken}`, 'Edit_Workorder_Window');
		}, 1000);
	};

	// We only want the user to be able to move items that can be scheduled.
	const allowed = (typeof props.event.allow_scheduling == 'undefined' ? true : props.event.allow_scheduling);
	const isComplete = (props.event.is_work_done ? '(C)' : '');
	// console.info('Do we allow scheduling for ',props.event.product_name, '? ', allowed);

	if (allowed) {
		return (
			<li ref={drag} className={props.event.calendarClass} onClick={onClickEvent}>{props.event.product_name}</li>
		);
	}

	return (
		<li className={props.event.calendarClass} onClick={onClickEvent}>{isComplete} {props.event.product_name}</li>
	);

}
