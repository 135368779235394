import { atom } from "recoil";

export const isSecurityRoleLoadingState = atom({
    key: 'isSecurityRoleLoadingState',
    default: true
});

export const isUserTerritoriesLoadingState = atom( {
    key: 'isUserTerritoriesLoadingState',
    default: true
});
