import './bioharmony.scss';
import ComplexTable from "./ComplexTable";
import TerritoristTable from "./TerritoriestTable"
import MaintenanceEvents from "./MaintenanceEvents";

import QM from "./QM";
import Events from "./Events";
import { SelectedDayAgenda } from "./SelectedDayAgenda";
import TitleBar from "./TitleBar";

const BioHarmony = {
	UpcomingEvents: Events,
	MaintenanceEvents: MaintenanceEvents,
	SelectedDayAgenda: SelectedDayAgenda,
	QM: QM,
	TitleBar: TitleBar,
	ComplexTable: ComplexTable,
	TerritoristTable: TerritoristTable
};

export default BioHarmony;
