import _ from "lodash";
import React, {useEffect, useState} from "react";
import './style.scss';
import {IComplexTableProps} from "../../app/SecurityManager/interfaces";
import Pagination from "./Pagination";



export default function ComplexTable( props:IComplexTableProps ){

	const [ data, setData ] = useState( props.data );
	const [ search, setSearch ] = useState('');
	const [ selectedRows, setSelectedRows ] = useState<number[]>([]);

	useEffect(() => {

		setData(props.data.filter( item => {
			return Object.values( item ).find( value => typeof value === "string" && value.includes( search ));
		}));

	}, [ search ]);
	useEffect(() => {

		setData( [...props.data] );

	}, [ props.data ]);
	const selectAllRows = ( checked:boolean ) => {

		if( !checked ){
			setSelectedRows([]);
		}

		else {
			setSelectedRows( data.map( user => user.id ));
		}

	}

	const selectRow = ( sid:string, checked:boolean ) => {

		let newValues;
		const id = Number(sid);

		if( checked ){
			newValues = [ ...selectedRows, id ].filter(( x:number, i:number, arr:number[] ) => arr.indexOf(x) === i);
		}

		else {
			newValues = selectedRows.filter(( value:number ) => value !== id );
		}

		setSelectedRows( newValues );

	}

	const isChecked = ( id:string ) => {
		return !!selectedRows?.find(( checked:number ) => checked === Number(id) );
	}

	const actions = {
		selectAllRows,
		selectRow,
		isChecked
	}

	return (
		<>

			<div className={`table-header ${props.color}-gradient button-height`}>

				{props.showSearch &&
					<div className="float-right" id="search-box">
						Search&nbsp;<input type="text" name="grid-header-table-search" id="grid-header-table-search" className="input mid-margin-left" value={search} onChange={(e)=>setSearch(e.target.value)}/>
					</div>
				}
				<div className="clearfix" />

			</div>

			<table className="table grid-table responsive-table responsive-table-on mb-0">
				<thead>
				<tr>
					{ props.cells.map( cells => {

						cells = Array.isArray(cells) && cells || [ cells ];

						return (
							<th className={`header ${cells[0].className}`} scope="col">
								{ cells.map( cell => {
									return cell.header ? cell.header( cell.title, actions ) : cell.title;
								})}
							</th>
						);

					})}
				</tr>
				</thead>
				<tbody>
				{ data.map( rowData => (

					<tr>
						{ props.cells.map( cells => {

							cells = Array.isArray(cells) && cells || [ cells ];

							return (
								<td className={`selectable ${cells[0].className}`}>
								{ cells.map( cell => {
									const value = _.get(rowData, cell.dataKey );
									return cell.format ? cell.format( value, rowData, actions ) : value;
								})}
								</td>
							);

						})}
					</tr>

				))}
				</tbody>
			</table>

			<form method="post" action="" className={`table-footer ${props.color}-gradient button-height`}>

				{/* OPTIONAL PAGINATION */}
				{ props.showPagination && <div className="float-right"><Pagination /></div>}


				<div className="button-group">
				{/*	<button type="button" className={`button ${props.color}-gradient glossy ${props.color=='red'?'hidden':''}`}>Delete</button>*/}
				{/*	<button type="button" className={`button ${props.color}-gradient glossy ${props.color=='red'?'hidden':''}`}>Archive</button>*/}
				</div>

			</form>

		</>
	)

}
