import React from "react";
import axios from "axios";
import { atom, selector, selectorFamily } from "recoil";
import { API_HOSTNAME } from "../recoil/applicationData";

export const getAllQuery = selector({
	key: 'getAllUsersQuery',
	get: async ({ get }): Promise<any[]> => {
		// get user data
		return axios.get(`${API_HOSTNAME}/v1/users`, {

			headers: {
				// 'Authorization': authToken
			}

		}).then(response => {

			return response.data.data;

		}).catch(e => {

			console.log(e);
			// window.location.href = `https://dev2.bioharmony.xyz/`;

		});
	}
});

export const usersState = atom({
	key: 'users',
	default: getAllQuery
});

export const activeUsersState = selector({
	key: 'activeUsers',
	get: ({ get }) => {
		return get(usersState).filter(user => user.meta.isActive === 1);
	}
});

export const archivedUsersState = selector({
	key: 'archivedUsers',
	get: ({ get }) => {
		return get(usersState).filter(user => user.meta.isActive === 0);
	}
});

export const getUserQuery = selectorFamily({
	key: 'getUserQuery',
	get: userId => async ({ get }) => {
		return get(usersState).find(user => Number(user.id) === Number(userId));
	},
	set: user => async ({ get, set }, newValue) => {

	}
})
