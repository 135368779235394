import React, { useEffect, useState } from "react";
import TerritoriesTable from "../TerritoriesTable";
import * as Users from "../../../api/users";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useSecurityModel } from "../../../models/useSecurityModel";
import { getUserQuery } from "../../../api/users";
import _ from 'lodash';
import { isSecurityRoleLoadingState } from "../../../recoil/securityData";
import { useSecurityApi } from "../../../models/useSecurityModel/api";

interface territoryListType {
    territoryId: Number,
    userId: Number,
    xrefId: Number,
    id: Number,
    code: String,
    name: String,
    note: String,
    startDate: String,
}

interface _territoriesListType {
    territoryId: Number,
    territoryName: String,
}

export default function PersonTerritories(props: any) {
    const userRoleApi = useSecurityApi();

    const { loadSecurityRoles } = useSecurityModel();
    const setSecurityRolesLoadingState = useSetRecoilState(isSecurityRoleLoadingState);

    useEffect(() => {
        setSecurityRolesLoadingState(true);
        loadSecurityRoles().then(() => {
            setSecurityRolesLoadingState(false);
        });
        getUseSecurityDatas();
    }, []);

    const { id, tab } = useParams();
    const remoteUser = useRecoilValue(getUserQuery(id));
    const [user, setUser] = useState<any>(remoteUser);
    const [territoriList, setTerritoriList] = useState<territoryListType[]>([]);
    const [_territoriesList, _setTerritoriesList] = useState<_territoriesListType[]>([]);

    var _userTerritoriList: territoryListType[];
    _userTerritoriList = [];

    var _territoriList: _territoriesListType[];
    _territoriList = [];
    const getUseSecurityDatas = (async () => {
        const userTerritoriList = await userRoleApi.userTerritories(user.id);
        console.info('security user territories datas', userTerritoriList);
        const _territoryList = await userRoleApi.territoryList();
        var i = 1;
        if (userTerritoriList.data)
            for (var d of userTerritoriList.data) {
                const date = d.effective_start_date != null ? d.effective_start_date.split('T')[0] : '';
                if (d.is_active === 1) {
                    _userTerritoriList.push({
                        territoryId: d.territory_id,
                        userId: d.user_id,
                        xrefId: d.xref_id,
                        id: i,
                        code: d.code,
                        name: d.code + ' ' + d.name,
                        note: d.note,
                        startDate: date,
                    })
                    i = i + 1;
                }
            }

        await setTerritoriList(_userTerritoriList);

        if (_territoryList.data) {
            for (var d of _territoryList.data) {
                _territoriList.push({
                    territoryId: d.territory_id,
                    territoryName: d.territory_name
                })
            }
        }

        await _setTerritoriesList(_territoriList);


        console.info('STATE security user territories datas', _userTerritoriList);

        console.log('territories list', _territoriesList);
    });

    const activeUsers = useRecoilValue(Users.activeUsersState);
    return (
        <>
            {
                console.info('here final test territoristlist', _territoriesList)
            }
            <TerritoriesTable userTerritoriList={territoriList} __territoriesList={_territoriesList} color={'white'} getUseSecurityDatas={getUseSecurityDatas} />
        </>
    );
}