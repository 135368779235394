import React, { useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import LinkContainer from "react-router-bootstrap/lib/LinkContainer";

export default function LoginForm(props: { auth: (username: string, password: string) => void }) {

	const buttonRef = useRef<HTMLButtonElement>(null);
	const [username, setUsername] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const onClickLogin = (e?: any) => {
		e && e.preventDefault();
		setTimeout(() => {
			props.auth(username, password);
		}, 2000);

	};

	const handleKeyPress = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			buttonRef.current?.click();
		}
	}

	useEffect(() => {
		document.addEventListener("keypress", handleKeyPress);
		return () => (document.removeEventListener("keypress", handleKeyPress));
	}, []);

	return (
		<>

			<div className="text-light mb-3">
				<h3 style={{ color:'#87ba17', fontWeight:'bold' }}>Login</h3>
				<p className="text-left" style={{ fontSize: '0.7em' }}>
					Please enter your username and password.
				</p>
			</div>

			<ul className="inputs black-input large">
				<li>
					<span className="icon-user mid-margin-right" />
					<input type="text" value={username} className="input-unstyled" placeholder="Login" onChange={(e) => setUsername(e.target.value)} />
				</li>
				<li>
					<span className="icon-lock mid-margin-right" />
					<input type="password" value={password} className="input-unstyled" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
				</li>
			</ul>

			<p className="button-height">
				<LinkContainer to={'/forgot'}><button className="button grey-gradient glossy float-left">Forgot Password</button></LinkContainer>
				<button ref={buttonRef} className="button blue-gradient glossy float-right ml-1" id="login" onClick={onClickLogin}>Login</button>{' '}
				<br />
			</p>
		</>
	);

}
