import React, { useEffect, useState } from "react";
import { default as moment, Moment } from 'moment';
import { useRecoilState, useRecoilValue } from "recoil";
import { currentMonthState } from "../recoil/dashboardData";
import CalendarDay from "./CalendarDay";

export default function Calendar(props: {}) {

	const [month, setMonth] = useRecoilState(currentMonthState);
	const [weeks, setWeeks] = useState<Moment[][]>([]);

	useEffect(() => {

		let i = 0;
		const calendarWeeks = [];
		const calendarDays = [];
		const start = moment(month).day(i);
		const valid = [start.month(), month.month()];
		let current = moment(start);

		while (valid.includes(current.month()) || current.day() > 0) {
			calendarDays.push(moment(current));
			current.add(1, 'day');
		}

		while (calendarDays.length) {
			calendarWeeks.push(calendarDays.splice(0, 7));
		}

		setWeeks(calendarWeeks);

	}, [month]);

	const nextMonth = (e: any) => {
		e.preventDefault();
		const next = moment(month).add(1, "month");
		setMonth(next);
	}

	const prevMonth = (e: any) => {
		e.preventDefault();
		const prev = moment(month).add(-1, "month");
		setMonth(prev);
	}

	return (
		<table className="calendar fluid">
			<caption>
				<a href="#" className="cal-prev" onClick={prevMonth}>◄</a>
				<a href="#" className="cal-next" onClick={nextMonth}>►</a>
				{month.format("MMMM YYYY")}
			</caption>
			<thead>
				<tr>
					<th scope="col">Sun</th>
					<th scope="col">Mon</th>
					<th scope="col">Tue</th>
					<th scope="col">Wed</th>
					<th scope="col">Thu</th>
					<th scope="col">Fri</th>
					<th scope="col">Sat</th>
				</tr>
			</thead>
			<tbody>
				{weeks.map((week: Moment[]) => (
					<tr key={`week-${week[0].format()}`}>
						{ week.map((day: Moment) => <CalendarDay key={day.toISOString()} day={day} month={month} />)}
					</tr>
				))}
			</tbody>
		</table>
	);
}
