import React, { useState, useEffect } from "react";
import { default as moment, Moment } from 'moment';
import { useEventModel } from "../models/useEventModel";
import { IEvent } from "../models/useEventModel/interface";
import AgendaDropSlot from "./AgendaDropSlot";
import AgendaEvent from "./AgendaEvent";

interface IEventsByHour {
	[key: number]: IEvent[]
}

const slots = [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

export function SelectedDayAgenda(props: {}) {

	const { agendaDay: day, useEventsByDay, handleSelectNextDay, handleSelectPrevDay, handleUnselectDay } = useEventModel();
	const events = useEventsByDay(day as Moment);

	const [draggingEvent, setDraggingEvent] = useState<any>();

	const onDraggingStarted = React.useCallback((ev: any) => {
		setDraggingEvent(ev);
	}, []);

	const callbackFunction = () => {
		setDraggingEvent(null);
	}

	useEffect(() => {
	}, [draggingEvent]);

	const slotsRenderer = React.useMemo(() => {
		console.log('test draging event', draggingEvent);
		return slots.map((slot, idx) => slots[idx + 1] && <AgendaDropSlot key={idx} from={slot} to={slots[idx + 1]} />)
	}, [])

	const eventsRenderer = React.useMemo(() => {
		return Object.entries(events.reduce((eventsByHour: IEventsByHour, event: IEvent) => {
			const hour = moment(event.scheduled_date).hour();
			eventsByHour[hour] = [...(eventsByHour[hour] || []), event];
			return eventsByHour;
		}, {})).reduce((acc: any[], cur: any) => {
			const [hour, groupedEvents] = cur;
			return [
				...acc,
				[...groupedEvents].map((event: IEvent, index: number) => {
					const isDragging = draggingEvent && draggingEvent.task_id !== event.task_id;
					console.log('is dragging', isDragging);
					return (<AgendaEvent key={event.task_id} setFunction={callbackFunction} event={event} num={index + 1} on={groupedEvents.length} onDraggingStarted={onDraggingStarted} dragingEvent={isDragging} />)
				})
			]
		}, []);
	}, [draggingEvent]);

	if (!day) {
		return <></>;
	}

	return (
		<div className="agenda with-header" style={{ height: 689 }}>

			<ul className="agenda-time" style={{ zIndex: -10, backgroundColor: '#FFF', }}>
				<li className={`from-before to-7`}><span></span></li>
				<li className="from-7 to-8"><span>7 AM</span></li>
				<li className="from-8 to-9"><span>8 AM</span></li>
				<li className="from-9 to-10"><span>9 AM</span></li>
				<li className="from-10 to-11"><span>10 AM</span></li>
				<li className="from-11 to-12"><span>11 AM</span></li>
				<li className="from-12 to-13"><span>12 PM</span></li>
				<li className="from-13 to-14"><span>1 PM</span></li>
				<li className="from-14 to-15"><span>2 PM</span></li>
				<li className="from-15 to-16"><span>3 PM</span></li>
				<li className="from-16 to-17"><span>4 PM</span></li>
				<li className="from-17 to-18"><span>5 PM</span></li>
				<li className="from-18 to-19"><span>6 PM</span></li>
				<li className="from-19 to-20"><span>7 PM</span></li>
				<li className="at-20"><span>8 PM</span></li>
			</ul>

			<div className="agenda-header">

				<a href="#" title="Previous Day" className="agenda-previous" onClick={handleSelectPrevDay}><span className="icon-left-round" /></a>

				<a href="#" title="Close" className="agenda-next agenda-close" onClick={handleUnselectDay}><span className="red icon-cross-round" /></a>
				<a href="#" title="Next Day" className="agenda-next" onClick={handleSelectNextDay}><span className="icon-right-round" /></a>

				{day.format("dddd MMMM Do")}
			</div>

			<div className="agenda-events">

				{/* {slots.map((slot, index) => {

					if (slots[index + 1]) {
						return <AgendaDropSlot key={index} from={slot} to={slots[index + 1]} />
					}

				})} */}
				{slotsRenderer}
				{/* {eventsRenderer} */}
				{

					Object.entries(events.reduce((eventsByHour: IEventsByHour, event: IEvent) => {
						console.info("reduce", event);
						const hour = moment(event.scheduled_date).hour();
						eventsByHour[hour] = [...(eventsByHour[hour] || []), event];
						return eventsByHour;
					}, {})).reduce((acc: any[], cur: any) => {
						const [hour, groupedEvents] = cur;
						return [
							...acc,
							[...groupedEvents].map((event: IEvent, index: number) => {
								const isDragging = draggingEvent && draggingEvent.task_id !== event.task_id;
								console.log('is dragging', isDragging);
								return (<AgendaEvent key={event.task_id} setFunction={callbackFunction} event={event} num={index + 1} on={groupedEvents.length} onDraggingStarted={onDraggingStarted} dragingEvent={isDragging} />)
							})
						]
					}, [])
				}

			</div>

		</div>
	);

}
