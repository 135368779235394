import {Moment} from "moment/moment";
import {atom} from "recoil";
import {IBaseEvent, IStats} from "./interface";

export const baseEventsState = atom({
	key: 'baseEventsState',
	default: [] as IBaseEvent[]
});

export const statsState = atom({
	key: 'statsState',
	default: {
		completed: 0,
		shipped: 0,
		approved: 0,
		pending: 0,
		amended: 0
	} as IStats
});

export const agendaDayState = atom({
	key: 'agendaDayState',
	default: null as Moment|null
});
