import ReCog from "@praetorian-studio/recog";
import axios from "axios";
import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import Developr from '../developr/index';
import {
	activeUserState, API_HOSTNAME,
	authTokenState, urlParamToken
} from "../recoil/applicationData";
import Dashboard from "./Dashboard";
import SecurityManager from "./SecurityManager/index";
import BioharmonyLegacyMenu from "../bioharmony/BioharmonyLegacyMenu";

export default function Application(props: {}) {

	const [Recog, status] = ReCog.useRecog();
	const [urlParam, setUrlParam] = useRecoilState(urlParamToken);
	const [activeUser, setActiveUser] = useRecoilState(activeUserState);
	const history = useHistory();

	function Logout() {
		Recog.logout();
		history.push('/');
	}

	useEffect(() => {
		const legacyToken = Buffer.from(Recog.idToken || '').toString("hex").substr(-32);
		setUrlParam(legacyToken);
	}, [Recog.idToken])

	useEffect(() => {
		// get dashboard data
		axios.get(`${API_HOSTNAME}/v1/application/activeUser`, {

			headers: {
				'Authorization': `Bearer ${Recog.idToken}`
			}

		}).then(response => {
			setActiveUser(response.data);

		}).catch(e => {

			setActiveUser({
				lastName: 'none',
				firstName: 'none',
				position: 'none',
				id: 1,
				user: 'none'
			});

		});

	}, [])

	return (
		<>
			<Developr.Header title="BioHarmony Data Central" />

			{/* Button to open/hide menu */}
			<a href="#" id="open-menu"><span>Menu</span></a>

			{/* Button to open/hide shortcuts */}
			<a href="#" id="open-shortcuts"><span className="icon-thumbs" /></a>

			{/* Main content */}
			<section role="main" id="main">

				{/* Visible only to browsers without javascript */}
				<noscript className="message black-gradient simpler">Your browser does not support JavaScript! Some features won't work as expected...</noscript>

				<React.Suspense fallback={<h3>Loading...</h3>}>
					<Switch>
						<Route exact path="/logout" render={() => {
							Logout();
							return <Redirect to="/" />;
						}} />
						<Route path="/security" component={SecurityManager} />
						<Route path="/" component={Dashboard} />
					</Switch>
				</React.Suspense>

			</section>
			{/* End main content */}

			{/* Side tabs shortcuts */}
			<ul id="shortcuts" role="complementary" className="children-tooltip tooltip-right">
				{/* Active shortcut */}
				<li className="current"><a href="./" className="shortcut-dashboard" title="Dashboard">Dashboard</a></li>
				{/* Background shortcut */}
				<li><a href="#" className="shortcut-messages" title="Messages">Messages</a></li>
				{/* Disabled shortcut */}
				<li><span className="shortcut-notes" title="Notes">Notes</span></li>
			</ul>

			{/* Sidebar/drop-down menu */}
			<section id="menu" role="complementary">

				{/* This wrapper is used by several responsive layouts */}
				<div id="menu-content">

					{activeUser && <>
						<header>
							{activeUser.position}
						</header>

						<div id="profile">
							<img src="img/user.png" width="64" height="64" alt="User image" className="user-icon" />
							Hello
							<span className="name">{activeUser.firstName}</span>
						</div>
					</>}

					{/* By default, this section is made for 4 icons, see the doc to learn how to change this, in "basic markup explained" */}
					<ul id="access" className="children-tooltip">
						<li className="disabled"><span className="icon-inbox" /><span className="count"></span></li>
						<li className="disabled"><span className="icon-calendar" /></li>
						<li>
							<a href='#' onClick={() => { history.push('/security') }} title="Security">
								<span className="icon-user" />
							</a>
						</li>
						<li className="disabled"><span className="icon-gear" /></li>
					</ul>

					<section className="navigable">
						<div>
							<BioharmonyLegacyMenu />
						</div>
					</section>

				</div>
				{/* End content wrapper */}

				{/* This is optional */}
				<footer id="menu-footer">
					{/* Any content */}
				</footer>

			</section>
			{/* End sidebar/drop-down menu */}
		</>
	);

}
