import React, { useState, useRef, useEffect } from "react";
import { Modal, Form, Table } from "react-bootstrap";
import Developr from "./index";
import './printPopup.scss';
import ReactToPrint from "react-to-print";
import { useReportApi } from "../models/useReportModel/api";
import { useSecurityApi } from "../models/useSecurityModel/api";

interface SummaryListMonthType {
    type: String,
    territory: String,
    plant: String,
    scheduledDate: String,
    mp: String
}

interface DetailedListMonthType {
    serviceType: String,
    title: String,
    plant: String,
    whoLivesAtSite: String,
    securitySystem: Number,
    needKeycode: Number,
    dogsOnPremises: Number,
    territory: String,
    scheduledDate: String,
    securityNote: String,
    phone: String | null,
    address: String,
    street: String,
    mp: String
}

export default function PrintPopup(props: any) {

    const monthlyValue = [
        'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'Octorber', 'November', 'December'
    ];
    const ReportApi = useReportApi();
    const userRoleApi = useSecurityApi();
    const printContent = useRef<HTMLDivElement>(null);
    const [ref1, setRef1] = useState(null);
    const genWindowForPrint = (() => {
        console.log('print content test', printContent.current);
    })

    useEffect(() => {
        // console.log("get_data_");
    }, []);

    useEffect(() => {
        getUseSecurityDatas();
    })

    const reportList = [
        {
            id: 1,
            report: 'summary-wo-month-list',
            description: 'Summary List of Tasks for the Month',
            monthOption: true,
            yearOption: true,
            dayOption: false
        },
        {
            id: 2,
            report: 'detailed-wo-month-list',
            description: 'Detailed List of Tasks for the Month',
            monthOption: true,
            yearOption: true,
            dayOption: false
        },
        {
            id: 3,
            report: 'summary-wo-daily-list',
            description: 'Summary List of Tasks for the Day',
            monthOption: true,
            yearOption: true,
            dayOption: true
        },
        {
            id: 4,
            report: 'detailed-wo-daily-list',
            description: 'Detailed List of Tasks for the Day',
            monthOption: true,
            yearOption: true,
            dayOption: true
        }
    ];

    const [printFlag, setPrintFlag] = useState(false);

    const [printPdfFlag, setPrintPdfFlag] = useState(true);

    const [dateFormFlag, setDateFormFlag] = useState({
        year: reportList[0].yearOption,
        month: reportList[0].monthOption,
        day: reportList[0].dayOption,
        reportTitle: reportList[0].description
    });

    const [summaryListMonth, setSummaryListMonth] = useState<SummaryListMonthType[]>([])
    const [detailedListMonth, setDetailedListMonth] = useState<DetailedListMonthType[]>([])

    const [date, setDate] = useState({
        currentYear: new Date().getFullYear(),
        currentMonth: monthlyValue[new Date().getMonth()],
        currentDay: new Date().getDate()
    })

    const currentDate = {
        currentYear: new Date().getFullYear(),
        currentMonth: monthlyValue[new Date().getMonth()],
        currentDay: new Date().getDate()
    }

    // console.log('currentDate', currentDate);
    const dateFormChange = (item: any) => {
        var dateFlagArray = item.target.value.split('-');
        setDateFormFlag({
            year: dateFlagArray[0] && dateFlagArray[0] == 1 ? true : false,
            month: dateFlagArray[1] && dateFlagArray[1] == 1 ? true : false,
            day: dateFlagArray[2] && dateFlagArray[2] == 1 ? true : false,
            reportTitle: dateFlagArray[3]
        })
    }

    const cm = currentDate.currentMonth;
    const [countDate, setCountDate] = useState(
        cm == 'February' ?
            ((currentDate.currentYear % 4 == 0 && currentDate.currentYear % 100 != 0) ? 29 : 28) :
            cm == 'January' || cm == 'March' || cm == 'May' || cm == 'July' || cm == 'August' || cm == 'Octorber' || cm == 'December' ? 31 : 30
    );

    const yearFormChange = (item: any) => {
        setDate({
            currentYear: item.target.value,
            currentMonth: date.currentMonth,
            currentDay: date.currentDay
        })
    }

    const monthSelectionChange = (item: any) => {
        setDate({
            currentYear: date.currentYear,
            currentMonth: item.target.value,
            currentDay: date.currentDay
        })
        console.log('month sleection');
        const m = item.target.value;
        if (item.target.value == 'February') {
            if (date.currentYear % 4 == 0 && date.currentYear % 100 != 0) {
                setCountDate(29);
            } else {
                setCountDate(28);
            }
        } else if (m == 'January' || m == 'March' || m == 'May' || m == 'July' || m == 'August' || m == 'Octorber' || m == 'December') {
            setCountDate(31);
        } else {
            setCountDate(30);
        }
    }

    const dayFormChange = (item: any) => {
        setDate({
            currentYear: date.currentYear,
            currentMonth: date.currentMonth,
            currentDay: item.target.value
        })
    }

    const [currentTime, setCurrentTime] = useState('');

    const print = async function () {

        const getMonthNumber = monthlyValue.indexOf(date.currentMonth) + 1;
        var summaryData, detailedData;
        if (dateFormFlag.day) {
            summaryData = await ReportApi.reportData(2, getMonthNumber, date.currentYear, date.currentDay);
            detailedData = await ReportApi.reportData(2, getMonthNumber, date.currentYear, date.currentDay);
        } else {
            summaryData = await ReportApi.reportData(1, getMonthNumber, date.currentYear, 0);
            detailedData = await ReportApi.reportData(1, getMonthNumber, date.currentYear, 0);
        }

        const reportResult = await ReportApi.reportData(1, getMonthNumber, date.currentYear);

        console.log('report detail result ----', reportResult);

        var _summaryListMonth: SummaryListMonthType[];
        _summaryListMonth = [];

        var _detailedListMonth: DetailedListMonthType[];
        _detailedListMonth = [];
        if (summaryData.data)
            for (var d of summaryData.data) {
                const _dateHour = parseInt((d.scheduled_date.split('T')[1]).split(':')[0]);
                const _dateMin = (d.scheduled_date.split('T')[1]).split(':')[1];
                const dateTime = _dateHour > 12 ? _dateHour - 12 + ':' + _dateMin + ' pm' : _dateHour == 12 ? _dateHour + ':' + _dateMin + ' pm' : _dateHour + ':' + _dateMin + 'am';
                _summaryListMonth.push({
                    type: d.task_type,
                    mp: d.maintenance_provider,
                    territory: d.territory_name,
                    plant: d.title,
                    scheduledDate: d.month.toString() + ' ' + d.day.toString() + ', ' + d.year + ' ' + dateTime
                })
            }

        if (detailedData.data)
            for (var d of detailedData.data) {
                const _dateHour = parseInt((d.scheduled_date.split('T')[1]).split(':')[0]);
                const _dateMin = (d.scheduled_date.split('T')[1]).split(':')[1];
                const dateTime = _dateHour > 12 ? _dateHour - 12 + ':' + _dateMin + ' pm' : _dateHour == 12 ? _dateHour + ':' + _dateMin + ' pm' : _dateHour + ':' + _dateMin + 'am';
                const phoneNumber = formatPhoneNumber(d.phone);
                console.log('phone number', phoneNumber, typeof (phoneNumber));
                _detailedListMonth.push({
                    serviceType: d.service_type,
                    title: d.title,
                    plant: d.title,
                    whoLivesAtSite: d.primary_contact_type,
                    securitySystem: d.has_security,
                    needKeycode: d.has_keycode,
                    dogsOnPremises: d.has_dogs,
                    territory: d.territory_name,
                    scheduledDate: d.month.toString() + ' ' + d.day.toString() + ', ' + d.year + ' ' + dateTime,
                    securityNote: '(' + d.note + ')',
                    phone: phoneNumber,
                    street: d.street,
                    address: d.city + ' ' + d.postal + ' ' + d.province + ' ' + d.country,
                    mp: d.maintenance_provider,
                })
            }

        console.info('summaryListMonth', summaryListMonth, typeof (summaryListMonth));
        await setSummaryListMonth(_summaryListMonth);

        console.info('summaryListMonth', detailedListMonth, typeof (detailedListMonth));
        await setDetailedListMonth(_detailedListMonth);

        getTime();
        setPrintFlag(true);
    }

    const getTime = (() => {
        const time = new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
        setCurrentTime(time);
    })

    const printPdf = (() => {
        // console.debug('here print pdf');
        setPrintFlag(true);
        setPrintPdfFlag(false);
        props.hideModal();
        console.debug('print flag', printFlag, 'print pdf flag', printPdfFlag);
        dateFormFlag['reportTitle'] = 'Summary List of Tasks for the Month';
    })

    const beforePrint = (() => {
        setPrintPdfFlag(false);
    })

    // console.log("count--", countDate);

    const getUseSecurityDatas = (async () => {
        const data = await userRoleApi.userTerritories(7);
        // console.debug('security user territories datas', data);
        const territoryList = await userRoleApi.territoryList();
        // console.debug('security territory List data', territoryList);
    })

    const cancelPrint = (() => {
        setPrintFlag(false);
        dateFormFlag['reportTitle'] = 'Summary List of Tasks for the Month';
        props.hideModal();
        setDateFormFlag({
            year: reportList[0].yearOption,
            month: reportList[0].monthOption,
            day: reportList[0].dayOption,
            reportTitle: reportList[0].description
        })
    })

    const formatPhoneNumber = ((phoneNumber: any) => {

        let cleaned = ('' + phoneNumber).replace(/\D/g, '');

        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3]
        };

        return null
    });
    return (
        <>
            <Modal show={props.isShow} onHide={props.hideModal} size="lg" className="modalContent" centered>
                <Modal.Header className={printFlag ? "modalHeaderAfterPrint" : "modalHeader"} >
                    <Modal.Title>
                        {/* {dateFormFlag.reportTitle == '' ? props.label : dateFormFlag.reportTitle} */}
                        {printFlag ? '' : props.label}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ paddingBottom: '50px' }}>
                    {!printFlag ? (
                        <div>
                            <div className="_row">
                                <div className="col-md-6">
                                    <Form.Group controlId="exampleForm.ControlSelectGroup" style={{ height: '100%' }}>
                                        <Form.Control as="select" className="dropdown-basic" onChange={dateFormChange} multiple>
                                            {/* <option selected disabled>&lt;&nbsp;Select a Report&nbsp;&gt;</option> */}
                                            {reportList.map((item, index) => (
                                                index == 0 ? (
                                                    <option id="firstOption" value={`${item.yearOption ? 1 : 0}-${item.monthOption ? 1 : 0}-${item.dayOption ? 1 : 0}-${item.description}`} selected>{item.description}</option>
                                                ) :
                                                    <option value={`${item.yearOption ? 1 : 0}-${item.monthOption ? 1 : 0}-${item.dayOption ? 1 : 0}-${item.description}`} >{item.description}</option>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </div>
                                <div className="col-md-1">
                                    &nbsp;
                                </div>
                                <div className="_col-md-5" style={dateFormFlag.month || dateFormFlag.year || dateFormFlag.day ? { display: 'block' } : { display: 'none' }}>
                                    <Form className="printFormGroup">
                                        {
                                            dateFormFlag.month ? (
                                                <Form.Group controlId="exampleForm.ControlSelectMonth" className="printForm" onChange={monthSelectionChange}>
                                                    <Form.Label>Month</Form.Label>
                                                    <Form.Control value={date.currentMonth} as="select" className="printFormControl">
                                                        {monthlyValue.map((item, index) => (
                                                            <option>{item}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            ) : <></>
                                        }
                                        {
                                            dateFormFlag.year ? (
                                                <Form.Group controlId="exampleForm.ControlSelectYear" className="printForm" onChange={yearFormChange}>
                                                    <Form.Label >Year</Form.Label>
                                                    <Form.Control value={date.currentYear} type="number" min={2015} className="printFormControl" />
                                                </Form.Group>
                                            ) : <></>
                                        }
                                        {
                                            dateFormFlag.day ? (
                                                <Form.Group controlId="exampleForm.ControlSelectDay" className="printForm">
                                                    <Form.Label>Day</Form.Label>
                                                    <Form.Control type="number" value={date.currentDay} onChange={dayFormChange} as="select" className="printFormControl">
                                                        {
                                                            Array.from(Array(countDate).keys()).map((item) => (
                                                                <option>{item + 1}</option>
                                                            ))
                                                        }
                                                    </Form.Control>
                                                </Form.Group>
                                            ) : <></>
                                        }
                                    </Form>
                                </div>
                            </div>
                        </div>) :
                        <div>
                            <div ref={printContent}>
                                <div className="printHeader">
                                    <div style={printPdfFlag ? { display: 'none' } : { display: 'block' }}>BioHarmony</div>
                                    <h1>Monthly Schedule For {dateFormFlag.month ? date.currentMonth : ' '} {dateFormFlag.day ? date.currentDay + ', ' : ''}  {dateFormFlag.year ? date.currentYear : ''}</h1>
                                    <br />
                                Printed: {currentDate.currentMonth}&nbsp;{currentDate.currentDay + ', '}{currentDate.currentYear}
                                    {
                                        printPdfFlag ?
                                            (<div>
                                                <div className="printButton">
                                                    <ReactToPrint
                                                        trigger={() => <Developr.Button icon="printer" label="Print" color="green" tone="glossy" />}
                                                        onBeforePrint={() => printPdf()}
                                                        onBeforeGetContent={() => beforePrint()}
                                                        content={() => printContent.current}
                                                    />
                                                    <div style={{ marginLeft: '15px' }}>
                                                        <Developr.Button label="Cancel" tone="glossy" onClick={cancelPrint} />
                                                    </div>
                                                </div>
                                            </div>) : <></>
                                    }

                                </div>
                                {console.log('dateform flag report title', dateFormFlag['reportTitle'])}
                                {dateFormFlag['reportTitle'] == 'Summary List of Tasks for the Month' || dateFormFlag['reportTitle'] == 'Summary List of Tasks for the Day' ?
                                    (<div className="printContainer">
                                        {summaryListMonth.toString() != '' ? (
                                            <div className="dataHeader">
                                                <h5> {summaryListMonth[0].territory} </h5>
                                            </div>) : <></>}
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Type</th>
                                                    <th>Maintenance Provider</th>
                                                    <th>Plant</th>
                                                    <th>Scheduled Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {console.log('here component summary list', summaryListMonth.toString())}

                                                {
                                                    summaryListMonth.map((item: any, index: any) => (
                                                        // index != 0 ? (
                                                        <tr>
                                                            <td>{index + 1}</td>
                                                            <td>{item.type}</td>
                                                            <td>{item.mp}</td>
                                                            <td>{item.plant}</td>
                                                            <td>{item.scheduledDate}</td>
                                                        </tr>
                                                        // ) : <></>
                                                    ))}
                                            </tbody>
                                        </Table>
                                    </div>) : (
                                        <>
                                            <>
                                                {summaryListMonth.toString() != '' ? (
                                                    <div className="dataDetailHeader">
                                                        <h5> {summaryListMonth[0].territory} </h5>
                                                    </div>) : <></>}
                                            </>
                                            <>
                                                {detailedListMonth.map((item: any, index: any) => (
                                                    <div className="detailed-info" >
                                                        <div className="_row" >
                                                            <div className="_col-md-1">
                                                                <p>{index + 1}</p>
                                                            </div>
                                                            <div className="_col-md-5">
                                                                <div className="_row" style={{ display: 'flex' }}>
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Service Type 123:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.serviceType}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Title</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.title}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Plant</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.plant}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Who Lives At Site?</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.whoLivesAtSite}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Security System?</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.securitySystem == 1 ? 'Yes' : 'NO'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Need Keycode?</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.needKeycode == 1 ? 'Yes' : 'NO'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Dogs on Premises?</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.dogsOnPremises == 1 ? 'Yes' : 'NO'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                            </div>
                                                            <div className="_col-md-5">
                                                                <div className="_row">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Maintenance Provider:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.mp}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginTop10" >
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Scheduled Date:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.scheduledDate}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginTop10">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Security Note:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.securityNote}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginTop10">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Phone Number:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyle">{item.phone}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="row marginTop10">
                                                                    <div className="_col-md-5">
                                                                        <p className="labelStyle">Address:</p>
                                                                    </div>
                                                                    <div className="_col-md-7">
                                                                        <p className="dataStyleStress" style={{ marginBottom: '0px' }}>{item.street}</p>
                                                                        <p className="dataStyle">{item.address}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}</>
                                        </>)}
                            </div>
                        </div>
                    }
                </Modal.Body>
                {
                    !printFlag ? (
                        <Modal.Footer>
                            <Developr.Button label="View" color="green" tone="glossy" onClick={print} />
                            <Developr.Button label="Close" tone="glossy" onClick={props.hideModal} />
                        </Modal.Footer>
                    ) : <></>
                }
            </Modal>
        </>
    );
}
