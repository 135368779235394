import React from "react";
import { useRecoilValue } from "recoil";
import Developr from "../developr";
import { IEvent, IMaintenance } from "../models/useEventModel/interface";
import { isDashboardLoadingState } from "../recoil/dashboardData";
import DashboardEventRow from "./DashboardEventRow";

export default function Events(props: { events: Array<IEvent | IMaintenance>, title?: string }) {

	// const events = useRecoilValue( eventsState );
	const events = props.events;
	const isDashboardLoading = useRecoilValue(isDashboardLoadingState);

	const type = new Map<number, { label: string, color: string }>();
	type.set(1, { label: 'Maintenance', color: 'green' });
	type.set(2, { label: 'PreAuth', color: 'blue' });
	type.set(3, { label: 'Incident', color: 'red' });

	return (
		<>
			<h3 className={`underline thin no-margin-bottom`}>
				{isDashboardLoading && <span className="loader small-margin-right" />}
				{props.title || "Events"}
			</h3>
			<React.Suspense fallback={<div>Loading...</div>}>
				<Developr.SimpleTable responsive headers={['Type', 'Description']}>
					{events.map((event, index) => (
						<DashboardEventRow key={index} event={event} />
					))}
				</Developr.SimpleTable>
			</React.Suspense>
		</>
	)

}
